import React from "react";

export const FilledStarSVG = ({color}:{color?:string}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00006 11.0612L3.5834 13.1029C3.43062 13.1862 3.28132 13.2209 3.13548 13.207C2.98965 13.1931 2.86118 13.1445 2.75007 13.0612C2.63895 12.9779 2.55562 12.8633 2.50007 12.7174C2.44451 12.5716 2.43757 12.4223 2.47923 12.2695L3.37507 8.45703L0.354232 5.89453C0.229232 5.78342 0.152843 5.65495 0.125065 5.50911C0.0972873 5.36328 0.104232 5.22092 0.145898 5.08203C0.187565 4.94314 0.263954 4.82856 0.375065 4.73828C0.486176 4.648 0.625065 4.59592 0.791732 4.58203L4.75007 4.22786L6.31256 0.602865C6.38201 0.450087 6.47923 0.335503 6.60423 0.259115C6.72923 0.182726 6.86118 0.144531 7.00006 0.144531C7.13895 0.144531 7.2709 0.182726 7.3959 0.259115C7.5209 0.335503 7.61812 0.450087 7.68756 0.602865L9.25006 4.2487L13.2084 4.58203C13.3751 4.59592 13.514 4.65148 13.6251 4.7487C13.7362 4.84592 13.8126 4.96398 13.8542 5.10286C13.8959 5.24175 13.8994 5.38064 13.8646 5.51953C13.8299 5.65842 13.7501 5.78342 13.6251 5.89453L10.6251 8.45703L11.5209 12.2695C11.5626 12.4223 11.5556 12.5716 11.5001 12.7174C11.4445 12.8633 11.3612 12.9779 11.2501 13.0612C11.139 13.1445 11.0105 13.1931 10.8646 13.207C10.7188 13.2209 10.5695 13.1862 10.4167 13.1029L7.00006 11.0612Z"
        fill={color ? color : "#FFC773"}
      />
    </svg>
  );
};
