import React from "react";

export const GoogleSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_361_18383)">
        <path
          d="M23.7663 12.2743C23.7663 11.4586 23.7001 10.6384 23.559 9.83594H12.2402V14.4569H18.722C18.453 15.9473 17.5888 17.2657 16.3233 18.1034V21.1018H20.1903C22.4611 19.0118 23.7663 15.9252 23.7663 12.2743Z"
          fill="#4285F4"
        />
        <path
          d="M12.2401 23.9974C15.4766 23.9974 18.2059 22.9348 20.1945 21.1005L16.3276 18.1021C15.2517 18.8341 13.8627 19.2486 12.2445 19.2486C9.11388 19.2486 6.45946 17.1365 5.50705 14.2969H1.5166V17.3878C3.55371 21.44 7.7029 23.9974 12.2401 23.9974Z"
          fill="#34A853"
        />
        <path
          d="M5.50277 14.3027C5.00011 12.8123 5.00011 11.1985 5.50277 9.70813V6.61719H1.51674C-0.185266 10.008 -0.185266 14.0028 1.51674 17.3936L5.50277 14.3027Z"
          fill="#FBBC04"
        />
        <path
          d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_361_18383">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
