export const commonApi = {
  endpoint: process.env.REACT_APP_API_URL,
  region: process.env.REACT_APP_AWS_REGION,
};

export const GOAL_TEMPLATES = "goal-templates";
export const GOAL_TEMPLATES_ROUTE = "/goal-templates";
export const GOALS = "goals";
export const GOALS_ROUTE = "/goals";
export const ACTIVITY_TEMPLATES = "activity-templates";
export const ACTIVITY_TEMPLATES_ROUTE = "/activity-templates";
export const ACTIVITIES = "activities";
export const ACTIVITIES_ROUTE = "/activities";
export const CHECK_INS = "check-ins";
export const CHECK_INS_ROUTE = "/check-ins";
export const USER = "user";

const AmplifyConfig = {
  AWS_AUTH_PARAMS: {
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_AWS_REGION,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      //  oauth: {
      //   userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_ID,
      //    userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      //    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      //    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
      //    scope: ["email", "openid", "profile"],
      //    redirectSignOut: process.env.NEXT_PUBLIC_AWS_COGNITO_REDIRECT_SIGN_OUT,
      //    redirectSignIn: process.env.NEXT_PUBLIC_AWS_COGNITO_REDIRECT_SIGN_IN,
      //    responseType: "code",
      //    options: {
      //      AdvancedSecurityDataCollectionFlag: false,
      //    },
      oauth: {
        userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        aws_project_region: process.env.REACT_APP_AWS_REGION,
        aws_cognito_region: process.env.REACT_APP_AWS_REGION,
        aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
        aws_user_pools_web_client_id: process.env.REACT_APP_AWS_APP_CLIENT_ID,
        domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
        redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
        scopes: ["email", "openid", "profile"],
        responseType: "code",
      },
      redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
    },
    Analytics: {
      AWSPinpoint: {
        enabled: true,
        // Amazon Pinpoint App Client ID
        appId: process.env.REACT_APP_AWS_PINPOINT_APP_ID,
        // Amazon service region
        region: process.env.REACT_APP_AWS_REGION,
        optOut: "NONE",
      },
    },
    API: {
      endpoints: [
        {
          name: "goal-templates",
          ...commonApi,
        },
        {
          name: "goals",
          ...commonApi,
        },
        {
          name: "activity-templates",
          ...commonApi,
        },
        {
          name: "activities",
          ...commonApi,
        },
        {
          name: "check-ins",
          ...commonApi,
        },
        {
          name: "invitation",
          ...commonApi,
        },
        {
          name: "user",
          ...commonApi,
        },
      ],
    },
  },
};
console.log("AmplifyConfig", AmplifyConfig)
export default AmplifyConfig;
