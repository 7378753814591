// Packages
import React from "react";
import { IonPage } from "@ionic/react";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import {
  EyeFilled,
  EyeInvisibleFilled,
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Form, notification } from "antd";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert } from "antd";
import "./SignIn.scss";
// Plugin Functions
import { authSignIn, postData } from "../../plugins/auth/auth";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { GoogleSVG } from "../../components/svgComponents/GoogleSVG";

interface IProps extends RouteComponentProps {
  userStore: any;
}

@inject("userStore")
@observer
class SignIn extends React.Component<IProps> {
  @observable public email = "";
  @observable public password = "";
  @observable public error: null | string = null;
  @observable public loading = false;
  @observable public loadingGoogle = false;
  @observable public userPreFilledInfo: any = "";
  @observable public urlParams: any = "";
  @observable public baseInit: any = {
    headers: {},
    response: true,
  };
  @observable public showPassword: boolean = false;

  public componentWillMount() {
    this.urlParams = new URLSearchParams(window.location.search);
    if (this.urlParams.get("invite") === null) {
      return;
    }
    document.cookie = `invitationId= ${this.urlParams.get("invite")}`;
  }


  public handleSubmit = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.loading = true;
    let resError: any;
    // let res = await authGoogleLogin();
    // console.log(res);
    const newUser = new URLSearchParams(this.props.location.search).get(
      "newUser"
    );
    resError = await authSignIn(
      this.props,
      this.email,
      this.password,
      newUser === "true" ? true : false
    );
    if (resError === "User is not confirmed.") {
      this.props.history.push({
        pathname: "/confirm-email",
        search: `?email=${this.email}`,
      });
    } else if (resError) {
      this.error = resError
        ? resError === "Username cannot be empty"
          ? "Email cannot be empty"
          : resError
        : "An error occurred";
      this.loading = false;
    }
    const cookies = document.cookie.split("; ");
    const invitationIdCookie = cookies.find((row) =>
      row.startsWith("invitationId=")
    );
    const invitationIdEncrypted =
      invitationIdCookie && invitationIdCookie.split("=")[1]; // Get the invitationId cookie
    const invitationId =
      invitationIdEncrypted && JSON.parse(atob(invitationIdEncrypted)); // Decrypt the invitationId cookie
    if (!invitationId) {
      return;
    }
    postData(
      "invitation",
      `/invitation/${invitationId.invitationId}`,
      this.baseInit
    ).then((res) => {
      if(res.status === 200){
        notification.success({
          message: "Invitation accepted!",
          description: "You have successfully accepted the invitation.",
        });
        document.cookie =
        "invitationId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
      this.error = resError;
    });
    this.loading = false;
  };

  public handleSubmitGoogle = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.loadingGoogle = true;
    try {
      console.log("signing in with google");
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
      this.props.userStore.getUserInfo();
      const cookies = document.cookie.split("; ");
      const invitationIdCookie = cookies.find((row) =>
        row.startsWith("invitationId=")
      );
      const invitationIdEncrypted =
        invitationIdCookie && invitationIdCookie.split("=")[1]; // Get the invitationId cookie
      const invitationId =
        invitationIdEncrypted && JSON.parse(atob(invitationIdEncrypted)); // Decrypt the invitationId cookie
      if (!invitationId) {
        return;
      }
      postData(
        "invitation",
        `/invitation/${invitationId.invitationId}`,
        this.baseInit
      ).then((res) => {
        notification.success({
          message: "Invitation accepted!",
          description: "You have successfully accepted the invitation.",
        });
      });
      document.cookie =
        "invitationId=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      this.loadingGoogle = false;
    } catch {
      this.loadingGoogle = false;
    }
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "email":
        this.email = target.value;
        break;
      case "password":
        this.password = target.value;
        break;
      default:
        console.log("Unknown form value change");
    }
  };

  public renderGoogleButton() {
    if (this.loadingGoogle) {
      return (
        <Button type="default" className="google-button my-1">
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="default"
          className="google-button my-1"
          onClick={this.handleSubmitGoogle}
        >
          <GoogleSVG /> Sign in with Google
        </Button>
      );
    }
  }

  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
        >
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
          data-cy="submit"
        >
          Sign In
        </Button>
      );
    }
  }

  render() {
    return (
      <IonPage className="ion-padding">
        <div className="center-text form-container m-y-auto">
          <h1 className="sign-in-header">Welcome to Goalden Hour</h1>
          <p className="sign-in-subheader">
            Simple goal based tool for everyone
          </p>

          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}
          <div className="login-form">{this.renderGoogleButton()}</div>
          <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item className="form-item">
              <label htmlFor="email" id="emailLabel" className="label-sign-in">
                Email
              </label>
              <Input
                className="input-sign-in"
                id="email"
                name="email"
                prefix={<MailOutlined />}
                placeholder="olivia@untitledui.com"
                onChange={this.handleChange}
                data-cy="email"
              />
            </Form.Item>

            <Form.Item className="form-item">
              <label
                htmlFor="password"
                id="passwordLabel"
                className="label-sign-in"
              >
                Password
              </label>
              <div className="flexBoxRow input-sign-in border">
                <Input
                  className="no-border"
                  id="password"
                  name="password"
                  prefix={<LockOutlined />}
                  type={this.showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={this.password}
                  onChange={this.handleChange}
                />
                <div
                  onClick={() => {
                    this.showPassword = !this.showPassword;
                  }}
                  className="kebabMenu padding-around"
                >
                  {this.showPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
                </div>
              </div>
              <Link to="/forgot-password">Forgot password?</Link>
            </Form.Item>
            <div className="m-x-auto">{this.renderSubmitBtn()}</div>

            <div className="auth-options">
              <Link to="/signup">
                New user? <span>Create Account</span>
              </Link>
            </div>

            <p className="terms-optin">
              By clicking “Sign in with Google” or “Continue with your email”
              <br />
              You agree to our &nbsp;
              <Link to="/terms" className="primary-link">
                terms
              </Link>
              &nbsp;and&nbsp;
              <Link to="/privacy" className="primary-link">
                privacy
              </Link>
              &nbsp;policies.
            </p>
          </Form>
        </div>
      </IonPage>
    );
  }
}

export default withRouter(SignIn);
