import React from "react";

export const TimesSVG = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C5.16667 14 3.59375 13.3924 2.28125 12.1771C0.96875 10.9618 0.222222 9.46528 0.0416667 7.6875C0.0138889 7.49306 0.0729167 7.32986 0.21875 7.19792C0.364583 7.06597 0.541243 7 0.748729 7C0.944021 7 1.11806 7.05556 1.27083 7.16667C1.42361 7.27778 1.51389 7.43056 1.54167 7.625C1.69444 9 2.28819 10.1562 3.32292 11.0938C4.35764 12.0312 5.58333 12.5 7 12.5C8.51389 12.5 9.80903 11.9618 10.8854 10.8854C11.9618 9.80903 12.5 8.51389 12.5 7C12.5 5.48611 11.9618 4.19097 10.8854 3.11458C9.80903 2.03819 8.51389 1.5 7 1.5C6.13889 1.5 5.34342 1.67778 4.6136 2.03333C3.8838 2.38889 3.26954 2.87778 2.77083 3.5H4.25C4.4625 3.5 4.64062 3.57145 4.78438 3.71435C4.92813 3.85727 5 4.03435 5 4.2456C5 4.45687 4.92813 4.63542 4.78438 4.78125C4.64062 4.92708 4.4625 5 4.25 5H0.75C0.5375 5 0.359375 4.92813 0.215625 4.78438C0.0718751 4.64063 0 4.4625 0 4.25V0.75C0 0.5375 0.0714514 0.359375 0.214354 0.215624C0.357271 0.0718745 0.534354 0 0.745604 0C0.956868 0 1.13542 0.0718745 1.28125 0.215624C1.42708 0.359375 1.5 0.5375 1.5 0.75V2.70833C2.13889 1.875 2.93403 1.21528 3.88542 0.729167C4.83681 0.243056 5.875 0 7 0C7.97222 0 8.88287 0.184722 9.73194 0.554166C10.581 0.923611 11.3199 1.42269 11.9486 2.0514C12.5773 2.68009 13.0764 3.41898 13.4458 4.26806C13.8153 5.11713 14 6.02778 14 7C14 7.97222 13.8153 8.88287 13.4458 9.73194C13.0764 10.581 12.5773 11.3199 11.9486 11.9486C11.3199 12.5773 10.581 13.0764 9.73194 13.4458C8.88287 13.8153 7.97222 14 7 14ZM7.75 6.375L9.625 8.25C9.77778 8.40278 9.85069 8.57639 9.84375 8.77083C9.83681 8.96528 9.75694 9.13889 9.60417 9.29167C9.45139 9.44444 9.27431 9.52083 9.07292 9.52083C8.87153 9.52083 8.69444 9.44444 8.54167 9.29167L6.48517 7.23517C6.41172 7.16172 6.35417 7.07855 6.3125 6.98565C6.27083 6.89274 6.25 6.79674 6.25 6.69763V3.74325C6.25 3.53265 6.32292 3.35613 6.46875 3.21369C6.61458 3.07123 6.79167 3 7 3C7.20833 3 7.38542 3.07187 7.53125 3.21562C7.67708 3.35937 7.75 3.5375 7.75 3.75V6.375Z"
        fill="#79777C"
      />
    </svg>
  );
};
