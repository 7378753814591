import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Amplify from 'aws-amplify';
import AmplifyConfig from './config';
import { Provider } from 'mobx-react';
import userStore from './stores/userStore';
import goalStore from './stores/goalStore';
import activityStore from './stores/activityStore';
import checkInStore from './stores/checkInStore';
import appStore from './stores/appStore';
import * as serviceWorker from './serviceWorker';
import { IonReactRouter, IonReactHashRouter } from "@ionic/react-router"
import 'typeface-roboto';

// Amplify Setup
Amplify.configure(AmplifyConfig.AWS_AUTH_PARAMS);

// Mobx Setup
const store = {
  userStore,
  goalStore,
  activityStore,
  checkInStore,
  appStore
}

const Router: any =
  process.env.REACT_APP_IS_ELECTRON === "true"
    ? IonReactHashRouter
    : IonReactRouter

ReactDOM.render(
  <Provider {...store}>
    <Router basename={`${process.env.PUBLIC_URL}`}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

if (process.env.REACT_APP_IS_ELECTRON === 'true' || process.env.REACT_APP_DISABLE_SERVICE_WORKER === 'true') {
  serviceWorker.unregister();
} else {
  serviceWorker.register({
    onUpdate: (swRegistration: ServiceWorkerRegistration) => { 
      store.appStore.updateServiceWorker(swRegistration);
    }
  });
}