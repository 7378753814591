// Packages
import React from 'react';
import { IonPage } from '@ionic/react';
// Type Definitions
import { RouteComponentProps } from 'react-router-dom';

class Terms extends React.Component<RouteComponentProps> {
  render() {
    return (
      <IonPage className="ion-padding scroll-page">
        <div className="longform-text">
          <h1 className="center-text bold-text">Terms of Use</h1>
          <p>Goalden Hour (the "website") is a productivity and personal development tool that can be used to discover new self-improvement activities as well as manage tasks &amp; routines. These Terms of Use, together with the Privacy Policy, and any other documents they expressly incorporate, set forth the terms and conditions upon which you may access and use the life &amp; health coaching services and productivity tools provided or operated by Goalden Hour including any content and functionality offered through the Service. ("Terms of Use" or "Agreement")</p>
          <p>We reserve the right to modify or replace these Terms of Use and/or to change, suspend or discontinue the Services at any time in our sole discretion. We may also impose limits on certain features and Services or restrict your access to parts or all of the Services without notice or liability. We may provide notice of changes to these Terms of Use or other matters by posting updated text on this Website or through the Services or other appropriate means of electronic communication, and your continued use of the Services following the posting of any change or modification to these Terms of Use will constitute your acceptance of the modified Terms of Use, and your use will be subject to the Terms of Use in effect at the time of your use. It is your responsibility to review these Terms of Use periodically for changes.</p>
          <h2 className="center-text bold-text">Use of the Service</h2>
          <h3 className="center-text">General</h3>
          <p>Subject to the terms and conditions of this Agreement, and during the term of this Agreement, Goalden Hour grants you a non-exclusive and non-transferable right to access and use the Service in accordance with the terms of this Agreement, and any documentation made available to you by Goalden Hour solely for purposes of your personal use of the Services. The Service is enabled by technology, software and certain content delivered electronically. You agree that you will not reverse-engineer, decompile, disassemble, translate, or otherwise attempt to obtain access to the source code of, any aspect of the Services, including any and all technology, software and content. You will not copy, reproduce, alter, create derivative works from, or otherwise modify the Services, including any and all technology, software and content. You will not lease, loan, sublicense, distribute, or otherwise provide others access to or with any aspect of the Services. Other than as expressly set forth in this Agreement, no license or other rights in or to the Services are granted to you, and all such licenses and rights are hereby expressly reserved.</p>
          <p>The Service has been designed by Goalden Hour with the purpose of assisting people achieve their goals through healthy lifestyle choices and behavior changes. We encourage you to consult with your healthcare provider before commencing the Services. You represent that you are in good enough health to begin these Services, which may include changes to your diet or level of physical activity, among other things. If you have been diagnosed with a chronic disease or other health condition, you represent that you have obtained the express approval from a health care professional to receive the Services before beginning. The Service requires your active engagement and participation. You understand that, despite your efforts, individual users results will vary for a variety of reasons and Goalden Hour cannot guarantee that you will achieve your goals.</p>
          <p className="bold-text">It is important to understand that the services do not include the provision of medical care by the company. goalden hour is not a licensed medical care provider. You should not change your treatment or care plan, medication or therapy based on information you received through the services. Suggestions for new habits are supplemental to any treatment or care provided by your health care provider and should not replace regular medical visits with your health care provider. Always seek the advice of your physician or other qualified health care practitioner regarding your medical condition or the use (or frequency) of any medication or medical device. Content provided in our platform is offered for informational purposes only and is not a recommendation or endorsement of any drug, device or treatment or representation that a particular drug, device or treatment is safe, appropriate, or effective for you. Questions regarding any drug, device or treatment should be directed to your healthcare provider. never disregard professional medical advice or delay in seeking it because of something you have read in connection with our services. always consult with your healthcare professional if you have any questions or concerns about your health or condition or experience any changes in your condition or health status.</p>
          <h3 className="center-text">Registration</h3>
          <p>You are required to register for the Service and provide certain information about yourself. All information we collect about you in connection with the Services is subject to our Privacy Policy. By using the Service, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy, including being contacted via email.</p>
          <p>You will receive a user account for our personal use that requires an e-mail and password. You are responsible for all activities that occur under your user account. You shall: (i) have sole responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of all data you provide; (ii) maintain the confidentiality of your password and user account information; (iii) use commercially reasonable efforts to prevent unauthorized access to, or use of, the Service, and notify the Company promptly of any such unauthorized use; and (iv) comply with all applicable local, state, and federal laws in using the Service.</p>
          <h3 className="center-text">Term and Termination</h3>
          <p>The term of this Agreement will begin upon your successful registration for the Service and will continue indefinitely unless terminated by either party as permitted herein.</p>
          <p>The Company may suspend or terminate your access to the Service if you (a) breach any term of this Agreement, or (b) engage in any conduct that the Company determines in its discretion may have an adverse effect on the Company or its reputation. Upon termination, you will no longer have access to the Service, mobile application or content provided through the Services. In addition to termination, Company reserves the right to pursue any and all remedies available to it.</p>
          <h3 className="center-text">Registration</h3>
          <p>You will provide our Company with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of these Terms, which may result in immediate termination of your Company account. You shall not use a false nature, e-mail address or phone number or otherwise enter information with the intent to impersonate another person.</p>
          <h3 className="center-text">Intellectual Property Ownership</h3>
          <p>Except for the limited license and use rights expressly granted to you under this Terms of Use during the Term, all title to and the rights in the Services, including any and all technology, software and content, including ownership rights to patents (registrations, renewals, and pending applications), copyrights, trademarks, trade secrets, the Company&rsquo;s or third party hardware, other technology, any derivatives of and all goodwill associated with the foregoing is the exclusive property of the Company and/or third parties.</p>
          <h3 className="center-text">Confidentiality</h3>
          <p>We will use and protect your personal information pursuant to the Privacy Policy. You agree not to reveal or disclose any confidential information of the Company, which includes information, materials and content provided in connection with the Service) for any purpose with the Company&rsquo;s consent.</p>
          <h3 className="center-text">Non-Confidential Information</h3>
          <p>Subject to any applicable terms and conditions set forth in our Privacy Policy, any other communication or material that you send to us through the Service, such as any questions, comments, suggestions or the like, is and will be deemed to be non-confidential and we will have no obligation of any kind with respect to such information. We will be free to use any ideas, concepts, know-how or techniques contained in such communication for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products.</p>
          <h3 className="center-text">Content</h3>
          <p>The information presented on or through the Service is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. This Service may provide information about health and wellness and certain health conditions. However, nothing on this Service should be construed as providing any kind of medical advice or recommendation, and should not be relied on as the basis for any clinical decision or action. Patients should not use the information contained on the Service for diagnosing a health problem or disease. Medical advice should always be sought from a qualified medical practitioner. We disclaim all liability and responsibility arising from any reliance placed on such materials by you.</p>
          <h3 className="center-text">Service Access and Changes</h3>
          <p>We reserve the right to withdraw or amend this Service or materials we provide in connection with the Service, in our sole discretion. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service, or the entire Service to users. We also may update the content on this Service from time to time, and we make reasonable efforts to maintain current information. However, we cannot guarantee that the content is complete or up-to-date.</p>
          <h3 className="center-text">Trademarks</h3>
          <p>Trademarks, service marks, graphics and logos used in connection with the Service are the trademarks of their respective owners. You are granted no right or license with respect to any of the trademarks mentioned above and any use of such trademarks. You acknowledge and agree that all text, graphics, photographs, trademarks, logos, visual interfaces, artwork, computer code and all other related content contained on the Service is owned by the Company or third parties and is protected by trade dress, copyright, patent and trademark laws, and various other intellectual property rights and unfair competition laws. Any reproduction, publication, further distribution or public exhibition of materials provided through the Service, in whole or in part, is strictly prohibited. Except as expressly provided in these Terms of Use, no part of the Service and no content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, distributed or transmitted in any way (including "mirroring") to any other computer, server, Service or other medium for publication or distribution or for any commercial enterprise, without the express prior written consent of the Company.</p>
          <h3 className="center-text">Third Party Links and Technology</h3>
          <p>Any and all third party technology or Services provided, made available, linked to, or otherwise accessible through the Service (" Third Party Technology") is provided solely as a convenience to you and not under our control. The Company does not endorse, recommend, or otherwise make any representations or warranties with respect to any Third Party Technology. The Company does not have any responsibility or liability to you for any Third Party Technology which you access and you use it at your own risk. Further, you agree to comply with any and all terms and conditions applicable to the use of Third Party Technology and otherwise ensure that you have obtained all rights, licenses, and clearances that may be necessary to use such Third Party Technology. We encourage you to review the terms of use and privacy policies applicable to such Third Party Technology.</p>
          <h3 className="center-text">Indemnity</h3>
          <p>You agree to defend, indemnify, and hold the Company, its officers, directors, partners, employees, contractors, agents, licensors, and suppliers, harmless from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your use of the Service.</p>
          <p className="bold-text">Disclaimer: Goalden Hour is not a medical organization. You should not consider the service or the documentation as medical advice of any kind, and the service is not intended to treat any illness, disease, or adverse medical condition or manage a chronic disease or condition. By using the Service, you expressly agree that use of the Service is at your sole risk. The Service is provided on an "as is" and "as available" basis. Neither The Company nor its officers, directors, managers, members, employees, agents, third-party content providers, designers, contractors, distributors, merchants, sponsors, licensors or the like (collectively, "Affiliates") warrant that use of the Service will be uninterrupted or error-free. Neither The Company nor its Affiliates warrant the accuracy, integrity or completeness of the content provided on the Service or the products or services offered for sale on the Service. The Company and its Affiliates specifically disclaim all warranties, whether expressed or implied, including but not limited to warranties of title, merchantability or fitness for a particular purpose. No oral advice or written information given by The Company or its Affiliates will create a warranty. Some states do not allow the exclusion or limitation of certain warranties, so the above limitation or exclusion may not apply to you.</p>
          <h3 className="center-text">Limitation of Liability</h3>
          <p>Except where prohibited by law, in no event will The Company or its affiliates be liable for any direct, indirect, incidental, special or consequential damages that result from your use of or inability to use the service, including but not limited to reliance by you on any information obtained from the service. The foregoing limitation of liability will apply in any action, whether in contract, tort or any other claim, even if an authorized representative of the company has been advised of or should have knowledge of the possibility of such damages. </p>
          <h3 className="center-text">Severability</h3>
          <p>The provisions of these Terms of Use are severable. The invalidity, in whole or in part, of any provision of these Terms of Use will not affect the validity or enforceability of any other of its provisions. If any of the provisions of these Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions will be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use will remain in full force and effect.</p>
          <p>Effective: January 06, 2020</p>
        </div>
      </IonPage>
    );
  }
};

export default Terms;