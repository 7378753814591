// Packages
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
} from "@ionic/react";
import React from "react";
import { action, observable } from "mobx";
import { inject, observer } from "mobx-react";
import { PlusCircleFilled } from "@ant-design/icons";
// Styles
import { Tag } from "antd";
// Type Definitions
import { IActivityTemplate } from "../../../shared/interfaces";

interface IProps {
  activity: IActivityTemplate;
  activityStore?: any;
  cardType: string;
}

@inject("activityStore")
@observer
class ActivityTempCard extends React.Component<IProps> {
  @observable
  public image = this.props.activity.image;

  @action.bound
  public selectTemplate = () => {
    this.props.activityStore.activeActivityTemplate = this.props.activity;
    this.props.activityStore.createActivityType = this.props.activity.type;
    this.props.activityStore.createActivityModal = true;
    this.props.activityStore.isGoalDetailForm = false;
  };

  render() {
    return (
      <IonCard
        className={`${"inspirationGoalTempCard temp-card"} ${
          this.props.cardType === "routine"
            ? "routine-temp-card"
            : "task-temp-card"
        }`}
      >
        <div className="temp-card-header">
          <IonCardHeader className="template-card-header">
            <IonCardTitle>{this.props.activity.name}</IonCardTitle>
          </IonCardHeader>
          <PlusCircleFilled
            className={`${"plus-button"} ${
              this.props.cardType === "routine" ? "routine-plus" : "task-plus"
            }`}
            onClick={() => this.selectTemplate()}
          />
        </div>

        <IonCardContent class="content-container">
          <p className="template-para">{this.props.activity.description}</p>
          <div className="card-tags">
            {this.props.activity.categories &&
              this.props.activity.categories.map((category) => {
                const title = category.toLowerCase();
                return (
                  <Tag key={category} title={title}>
                    {title}
                  </Tag>
                );
              })}
          </div>
          <br />
          <div className="img-container">
            <img
              src={this.image}
              alt={this.props.activity.name}
              onError={(e) => {
                this.image = "/assets/golden-hour.jpg";
              }}
            />
          </div>
        </IonCardContent>
      </IonCard>
    );
  }
}

export default ActivityTempCard;
