import React from "react";

export const NotFilledStarSVG = ({color}:{color?:string}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.33242 10.8945L6.99909 9.3112L9.68659 10.8945L8.97826 7.89453L11.2908 5.91536L8.22826 5.64453L6.99909 2.79036L5.76992 5.64453L2.70742 5.91536L5.04076 7.89453L4.33242 10.8945ZM6.99909 11.0612L3.58242 13.1029C3.42964 13.1862 3.28034 13.2209 3.13451 13.207C2.98867 13.1931 2.8602 13.1445 2.74909 13.0612C2.63798 12.9779 2.55464 12.8633 2.49909 12.7174C2.44353 12.5716 2.43659 12.4223 2.47826 12.2695L3.37409 8.45703L0.353255 5.89453C0.228255 5.78342 0.151866 5.65495 0.124089 5.50911C0.0963108 5.36328 0.103255 5.22092 0.144922 5.08203C0.186589 4.94314 0.262977 4.82856 0.374089 4.73828C0.4852 4.648 0.624089 4.59592 0.790755 4.58203L4.74909 4.22786L6.31159 0.602865C6.38103 0.450087 6.47826 0.335503 6.60326 0.259115C6.72826 0.182726 6.8602 0.144531 6.99909 0.144531C7.13798 0.144531 7.26992 0.182726 7.39492 0.259115C7.51992 0.335503 7.61714 0.450087 7.68659 0.602865L9.24909 4.2487L13.2074 4.58203C13.3741 4.59592 13.513 4.65148 13.6241 4.7487C13.7352 4.84592 13.8116 4.96398 13.8533 5.10286C13.8949 5.24175 13.8984 5.38064 13.8637 5.51953C13.8289 5.65842 13.7491 5.78342 13.6241 5.89453L10.6241 8.45703L11.5199 12.2695C11.5616 12.4223 11.5546 12.5716 11.4991 12.7174C11.4435 12.8633 11.3602 12.9779 11.2491 13.0612C11.138 13.1445 11.0095 13.1931 10.8637 13.207C10.7178 13.2209 10.5685 13.1862 10.4158 13.1029L6.99909 11.0612Z"
        fill={color ? color : "#3C4043"}
        fillOpacity="0.8"
      />
    </svg>
  );
};
