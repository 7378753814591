import { observable, action } from "mobx";
import {
  authCurrentUser,
  getData,
  getHeader,
  postData,
} from "../plugins/auth/auth";
import { IUser } from "../shared/interfaces";
import { USER } from "../config";
import { Auth } from "aws-amplify";
import { notification } from "antd";

class UserStore {
  // Observables
  @observable proposedUserEmail = "";
  @observable currentUser: IUser = {
    id: "",
    email: "",
    name: "",
    emailVerified: false,
    idToken: "",
    isAuthenticated: false,
    picture: "",
  };
  @observable showCelebration = false;
  @observable association: any = {
    mentor: {
      email: "",
      name: "",
    },
    team: {
      name: "",
    },
  };
  @observable showAssociation = false;

  baseInit: any = {
    headers: {},
    response: true,
  };

  @action.bound
  public async uploadUserProfile(profile: File) {
    let init = this.baseInit;
    let headers = await getHeader();
    if (!init) {
      init = {};
    }
    if (!init.headers) {
      init.headers = {
        Authorization: headers,
      };
    }
    if (headers) {
      init.headers.Authorization = headers;
      init.headers["Accept"] = "*/*";
    }
    const formData = new FormData();
    formData.append("profile", profile);
    // init.headers = {
    //   "Content-Type": 'multipart/form-data; boundary=???'
    // }
    init.body = profile;
    // postData(USER, "/user/upload/avatar", this.baseInit).then((res) => {
    //   console.log(res);
    //   if (res.status === 200) {
    //     console.log("Avatar uploaded successfully");
    //   }
    // });
    console.log(init.headers);
    const result = await fetch(
      process.env.REACT_APP_API_URL + "/user/upload/avatar",
      {
        method: "POST",
        headers: {
          Authorization: headers,
        },
        body: formData,
      }
    );
    const data = await result.json();
    console.log(data, "result");
    const awsCurrentUser = await authCurrentUser();
    if (awsCurrentUser === false) {
      return false;
    } else {
      awsCurrentUser.updateAttributes(
        [
          {
            Name: "picture",
            Value: data.link,
          },
        ],
        async (err: any, result: any) => {
          if (err) {
            console.log(err);
            notification.error({
              message: "Error",
              description: "Error uploading profile picture",
            });
            return false;
          } else {
            let res = await Auth.currentUserInfo(); // solution for refetching user info
            this.currentUser = {
              ...this.currentUser,
              picture: res.attributes.picture,
            };
            notification.success({
              message: "Success",
              description: "Profile picture uploaded successfully",
            });
            console.log(res);
            console.log(result);
            return true;
          }
        }
      );
    }
  }

  @action.bound
  public async fetchAssociation() {
    getData(USER, "/user/association", this.baseInit).then((res) => {
      console.log(res);
      if (res.status === 200) {
        this.showAssociation = true;
        this.association = res.data;
      } else {
        this.showAssociation = false;
        this.association = null;
      }
    });
  }
  // Actions
  @action.bound
  public async getUserInfo() {
    // Check AWS for user
    const awsCurrentUser = await authCurrentUser();
    const currentUserInformation = await Auth.currentUserInfo();
    console.log(awsCurrentUser, currentUserInformation, 'user info');
    if (awsCurrentUser === false) {
      return false;
    } else {
      if (awsCurrentUser.signInUserSession.idToken.payload) {
        this.currentUser = {
          id: awsCurrentUser.signInUserSession.idToken.payload.sub,
          email: currentUserInformation.attributes.email,
          name: currentUserInformation.attributes.name,
          emailVerified: awsCurrentUser.signInUserSession.idToken.payload
            .emailVerified
            ? true
            : false,
          idToken: awsCurrentUser.signInUserSession.idToken.jwtToken,
          picture: awsCurrentUser.signInUserSession.idToken.payload.picture ? awsCurrentUser.signInUserSession.idToken.payload.picture : '',
          isAuthenticated: true,
        };
      } else {
        this.currentUser = {
          id: awsCurrentUser.attributes.sub,
          email: awsCurrentUser.attributes.email,
          name: currentUserInformation.attributes.name,
          emailVerified: awsCurrentUser.attributes.emailVerified,
          idToken: awsCurrentUser.signInUserSession.idToken.jwtToken,
          picture: currentUserInformation.attributes.picture || '',
          isAuthenticated: true,
        };
      }
    }
    return true;
  }

  @action.bound
  public resetUser() {
    this.currentUser = {
      id: "",
      email: "",
      name: "",
      emailVerified: false,
      idToken: "",
      isAuthenticated: false,
      picture: "",
    };
  }

  @action.bound
  public async updateUserName(name: string) {
    // Check AWS for user
    const awsCurrentUser = await authCurrentUser();
    console.log(awsCurrentUser);
    if (awsCurrentUser === false) {
      return false;
    } else {
      awsCurrentUser.updateAttributes(
        [
          {
            Name: "name",
            Value: name,
          },
        ],
        async (err: any, result: any) => {
          if (err) {
            console.log(err);
            return false;
          } else {
            let res = await Auth.currentUserInfo(); // solution for refetching user info
            this.currentUser = {
              ...this.currentUser,
              name: res.attributes.name,
            };
            console.log(res);
            console.log(result);
            return true;
          }
        }
      );
    }
  }

  public isStudent() {
    const { email } = this.currentUser;
    return email.toLowerCase().includes(".edu"); // returns bool
  }
}

export default new UserStore();
