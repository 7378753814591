import React from "react";

export const ResultsSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_325_21821"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_325_21821)">
        <path
          d="M17.9375 19L15.875 16.9375C15.5833 17.1042 15.282 17.2396 14.971 17.3438C14.6599 17.4479 14.336 17.5 13.999 17.5C13.0271 17.5 12.2009 17.1581 11.5205 16.4744C10.8402 15.7907 10.5 14.9659 10.5 14C10.5 13.0278 10.8403 12.2014 11.5208 11.5208C12.2014 10.8403 13.0278 10.5 14 10.5C14.9722 10.5 15.7986 10.8402 16.4792 11.5205C17.1597 12.2009 17.5 13.0271 17.5 13.999C17.5 14.336 17.4479 14.6599 17.3438 14.971C17.2396 15.282 17.1042 15.5833 16.9375 15.875L19 17.9375L17.9375 19ZM13.9953 16C14.554 16 15.0278 15.8071 15.4167 15.4214C15.8056 15.0356 16 14.5634 16 14.0047C16 13.446 15.8071 12.9722 15.4214 12.5833C15.0356 12.1944 14.5634 12 14.0047 12C13.446 12 12.9722 12.1929 12.5833 12.5786C12.1944 12.9644 12 13.4366 12 13.9953C12 14.554 12.1929 15.0278 12.5786 15.4167C12.9644 15.8056 13.4366 16 13.9953 16ZM17 9H15.5V4.5H14V7H6V4.5H4.5V15.5H9V17H4.5C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H8.0625C8.17361 2.56944 8.40625 2.21181 8.76042 1.92708C9.11458 1.64236 9.52778 1.5 10 1.5C10.4722 1.5 10.8854 1.64236 11.2396 1.92708C11.5938 2.21181 11.8264 2.56944 11.9375 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V9ZM9.9956 4.5C10.2069 4.5 10.3854 4.42855 10.5312 4.28565C10.6771 4.14273 10.75 3.96565 10.75 3.7544C10.75 3.54313 10.6785 3.36458 10.5356 3.21875C10.3927 3.07292 10.2156 3 10.0044 3C9.79313 3 9.61458 3.07145 9.46875 3.21435C9.32292 3.35727 9.25 3.53435 9.25 3.7456C9.25 3.95687 9.32145 4.13542 9.46435 4.28125C9.60727 4.42708 9.78435 4.5 9.9956 4.5Z"
          fill="#79777C"
        />
      </g>
    </svg>
  );
};
