
export const getUsernameFromLocalStorage = () => {
  return localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_APP_CLIENT_ID}.LastAuthUser`);
}

export const getEmailFromLocalStorage = () => {
  return getAttributeFromUserData('email');
}

export const getAccessTokenFromLocalStorage = () => {
  const username = getUsernameFromLocalStorage();
  return localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_APP_CLIENT_ID}.${username}.accessToken`);
}

// for whatever reason, AWS Amplify stores UserAttributes in an object shaped as such:
/*
{
   "UserAttributes":[
      {
         "Name":"sub",
         "Value":"..."
      },
      {
         "Name":"email_verified",
         "Value":"..."
      },
      {
         "Name":"email",
         "Value":"..."
      }
   ],
   "Username":"..."
}
*/
// this function allows us to grab an attribute's value from this object
const getAttributeFromUserData = (attribute: string) => {
  const username = getUsernameFromLocalStorage();
  const userDataJson = localStorage.getItem(`CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_APP_CLIENT_ID}.${username}.userData`);
  if (!userDataJson) {
    return null;
  }
  const userData = JSON.parse(userDataJson);
  const attributeObj = userData.UserAttributes.find((ua: any) => ua.Name === attribute);
  return attributeObj.Value;
}