// Packages
import { IonHeader, IonTitle, IonToolbar, IonMenuButton } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

const Header: React.FC = () => {
  return (
    <IonHeader>
      <IonToolbar>
        <div className="header-content-wrapper">
          <IonTitle>
            <Link to="/" className="navbar-link">
              <img
                src={`${process.env.PUBLIC_URL}/assets/logomark.svg`}
                className="logomark"
                alt="Goalden Hour logomark"
              />
              <span className="logotype">Goalden Hour</span>
            </Link>
          </IonTitle>
          <IonMenuButton />
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
