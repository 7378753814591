import React from "react";

export default function ReviewNReflectSVG() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#FFFFFF8A"
        d="M7.6 12.25L5.45 10.1C5.26667 9.91667 5.03333 9.825 4.75 9.825C4.46667 9.825 4.23333 9.91667 4.05 10.1C3.86667 10.2833 3.775 10.5167 3.775 10.8C3.775 11.0833 3.86667 11.3167 4.05 11.5L6.9 14.35C7.1 14.55 7.33333 14.65 7.6 14.65C7.86667 14.65 8.1 14.55 8.3 14.35L13.95 8.7C14.1333 8.51667 14.225 8.28333 14.225 8C14.225 7.71667 14.1333 7.48333 13.95 7.3C13.7667 7.11667 13.5333 7.025 13.25 7.025C12.9667 7.025 12.7333 7.11667 12.55 7.3L7.6 12.25ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4C0 3.45 0.195833 2.97917 0.5875 2.5875C0.979167 2.19583 1.45 2 2 2H6.2C6.41667 1.4 6.77917 0.916667 7.2875 0.55C7.79583 0.183333 8.36667 0 9 0C9.63333 0 10.2042 0.183333 10.7125 0.55C11.2208 0.916667 11.5833 1.4 11.8 2H16C16.55 2 17.0208 2.19583 17.4125 2.5875C17.8042 2.97917 18 3.45 18 4V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20H2ZM2 18H16V4H2V18ZM9 3.25C9.21667 3.25 9.39583 3.17917 9.5375 3.0375C9.67917 2.89583 9.75 2.71667 9.75 2.5C9.75 2.28333 9.67917 2.10417 9.5375 1.9625C9.39583 1.82083 9.21667 1.75 9 1.75C8.78333 1.75 8.60417 1.82083 8.4625 1.9625C8.32083 2.10417 8.25 2.28333 8.25 2.5C8.25 2.71667 8.32083 2.89583 8.4625 3.0375C8.60417 3.17917 8.78333 3.25 9 3.25Z"
      />
    </svg>
  );
}
