// Packages
import React from "react";
import { IonPage, IonButton } from "@ionic/react";
import { Link } from "react-router-dom";
import { computed, action, observable, autorun } from "mobx";
import { disposeOnUnmount, inject, observer } from "mobx-react";
import { API, Auth, Analytics } from "aws-amplify";
// Plugin Functions
import { authSignOut, deleteData, getData } from "../../plugins/auth/auth";
// Styles
import "./UserProfile.scss";
import { Card, Descriptions, Avatar, notification } from "antd";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";
import ProfileSVG from "../../components/svgComponents/ProfileSVG";
import { USER } from "../../config";
import TeamsSVG from "../../components/svgComponents/TeamSVG";
import { EditFilled } from "@ant-design/icons";

interface IProps extends RouteComponentProps {
  userStore: any;
  checkInStore?: any;
}

@inject("userStore", "checkInStore")
@observer
class UserProfile extends React.Component<IProps> {
  @computed get user() {
    return this.props.userStore.currentUser;
  }

  @disposeOnUnmount
  public userAutoRun = autorun(() => {
    this.props.userStore.getUserInfo();
  });

  @observable association: any = {
    mentor: {
      email: "",
      name: "",
    },
    team: {
      name: "",
    },
  };

  @computed get firstLetter() {
    if (this.user.email) {
      return this.user.email[0].toUpperCase();
    } else {
      return "";
    }
  }
  baseInit: any = {
    headers: {},
    response: true,
  };
  componentDidMount() {
    this.props.userStore.fetchAssociation();
  }

  @action.bound
  public signOut() {
    authSignOut(this.props);
    this.props.checkInStore.checkIns = [];
  }
  @action.bound
  public removeLinkAccount() {
    deleteData(USER, "/user/association", this.baseInit).then((res) => {
      notification.success({
        message: "Success",
        description: "Account unlinked successfully",
      });
      this.props.userStore.association = {
        mentor: {
          email: "",
          name: "",
        },
        team: {
          name: "",
        },
      };
      this.props.userStore.showAssociation = false;
    });
  }
  @observable editUserName = false;
  @action.bound
  public updateName() {
    this.props.userStore.updateUserName(this.user.name);
  }

  public renderTitle = () => {
    const fileInputRef = React.createRef<HTMLInputElement>();
    const image = this.user;
    return (
      <div className="userDetailContainer">
        <div
          onClick={() => {
            if (fileInputRef.current) fileInputRef.current.click();
          }}
        >
          <Avatar className="picture-avatar">
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0];
                  this.props.userStore.uploadUserProfile(file);
                }
              }}
            />
            <img
              className="avatar-img"
              src={this.user.picture ? this.user.picture : "/assets/Avatar.png"}
              alt={this.firstLetter}
            />
          </Avatar>
        </div>
        <div className="userDetail">
          {this.editUserName && (
            <input
              type="text"
              value={this.user.name}
              onChange={(e) => (this.user.name = e.target.value)}
              onBlur={() => {
                this.updateName();
                this.editUserName = false;
              }}
            />
          )}
          {!this.editUserName && (
            <span className="userName">
              {this.user.name}{" "}
              <EditFilled onClick={() => (this.editUserName = true)} />
            </span>
          )}
          <span className="userEmail">{this.user.email}</span>
        </div>
      </div>
    );
  };

  render() {
    return (
      <IonPage className="scroll-page">
        <div className="main-area ion-padding flexBoxColumn flex-gap">
          <div className="user-profile-header">
            <h1 style={{ marginRight: "auto" }} className="SiteHeader">
              <div className="IconWrapper">
                <ProfileSVG className="active" />
              </div>
              Profile
            </h1>
          </div>
          <Card className="profile-card center">
            <div className="profile-container">
              <Descriptions title={this.renderTitle()}></Descriptions>
              <div className="buttons-container">
                <button className="reset-button">
                  <Link to="/forgot-password" className="empty">
                    Reset password
                  </Link>
                </button>
                {/* <div className="center-text marginLeft">
              <button className="ionic-button" onClick={this.signOut}>Sign Out</button>
              </div> */}
                <div className="center-text">
                  <IonButton
                    color="light"
                    className="button"
                    onClick={this.signOut}
                  >
                    Sign Out
                  </IonButton>
                </div>
              </div>
            </div>
          </Card>
          {this.props.userStore.showAssociation && (
            <Card className="small-profile-card center mt-2">
              <div className="userDetailContainer team-info-container">
                <TeamsSVG color={"#667085"} />
                <p className="team-name">
                  {this.props.userStore.association.team
                    ? this.props.userStore.association.team.name
                    : this.props.userStore.association.mentor.name
                    ? this.props.userStore.association.mentor.name
                    : ""}
                </p>
                <div className="center-text kebabMenu remove-link-account-button">
                  <IonButton
                    color="light"
                    className="button"
                    onClick={this.removeLinkAccount}
                  >
                    Remove link account
                  </IonButton>
                </div>
              </div>
            </Card>
          )}
        </div>
      </IonPage>
    );
  }
}

export default UserProfile;
