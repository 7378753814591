// Packages
import React from "react";
import { IonPage } from "@ionic/react";
import { autorun, observable } from "mobx";
import { disposeOnUnmount, inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import {
  LoadingOutlined,
  MailOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form } from "antd";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert, message } from "antd";
// Plugin Functions
import {
  authConfirmSignUp,
  resendConfirmationCode,
} from "../../plugins/auth/auth";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  userStore: any;
}

@inject("userStore")
@observer
class ConfirmEmail extends React.Component<IProps> {
  @observable public email = "";
  @observable public confirmCode = "";
  @observable public error = null;
  @observable public loading = false;
  @observable public confirmed = false;

  @disposeOnUnmount
  public dashboardAutorun = autorun(() => {
    this.email = this.props.location.hash.substring(1);
  });


  public handleSubmit = async (event: React.FormEvent) => {
    //event.preventDefault();
    this.loading = true;
    this.confirmed
      ? this.props.history.push({
          pathname: "/signin",
          search: "?newUser=true",
        })
      : this.confirmUser();
    this.loading = false;
  };

  confirmUser = async () => {
    const resError = await authConfirmSignUp(
      this.props,
      this.email,
      this.confirmCode
    );
    if (!resError) {
      this.confirmed = true;
    }
    // If page didn't navigate, an error was returned
    this.error = resError;
  };

  public requestNewCode = async () => {
    const username = this.props.location.hash.substring(1);
    const resError = await resendConfirmationCode(username);
    if (!resError) {
      message.success("new confirmation code sent", 5);
    } else {
      message.error(`🚨 Error sending code: ${resError}`, 5);
    }
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "email":
        this.email = target.value;
        break;
      case "confirmCode":
        this.confirmCode = target.value;
        break;
      default:
        console.log("Unknown form value change");
    }
  };

  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <Button type="primary" htmlType="submit" className="form-submit-button sign-in-button">
          <LoadingOutlined />
        </Button>
      );
    } else if (!this.confirmed) {
      return (
        <Button type="primary" htmlType="submit" className="form-submit-button sign-in-button">
          Confirm
        </Button>
      );
    } else {
      return (
        <Button type="primary" htmlType="submit" className="form-submit-button sign-in-button">
          Go to Sign In
        </Button>
      );
    }
  }

  render() {
    const title = this.confirmed ? "Email Confirmed!" : "Email Confirmation";
    return (
      <IonPage className="ion-padding">
        <div className="center-text form-container m-y-auto">
          <h1 className="sign-in-header">{title}</h1>

          {this.error ? (
            <Alert message={this.error === 'Username cannot be empty' ? 'Email cannot be empty' : this.error} type="error" showIcon />
          ) : (
            ""
          )}

          <Form onFinish={this.handleSubmit} className="login-form">
            {!this.confirmed && (
              <>
                <Form.Item className="form-item">
                  <label
                    htmlFor="email"
                    id="emailLabel"
                    className="label-sign-in"
                  >
                    Email
                  </label>
                  <Input
                    className="input-sign-in"
                    id="email"
                    name="email"
                    prefix={<MailOutlined />}
                    placeholder="Email"
                    value={this.email}
                    onChange={this.handleChange}
                  />
                </Form.Item>

                <Form.Item className="form-item">
                  <label
                    htmlFor="confirmCode"
                    id="confirmCode"
                    className="label-sign-in"
                  >
                    Confirm Code
                  </label>
                  <Input
                    name="confirmCode"
                    prefix={<SafetyOutlined />}
                    placeholder="6-digit code"
                    value={this.confirmCode}
                    onChange={this.handleChange}
                  />
                </Form.Item>
              </>
            )}
            <div className="m-x-auto">{this.renderSubmitBtn()}</div>
          </Form>

          <br />
          {!this.confirmed && (
            <>
              <Alert
                message={"Check your email for a confirmation code"}
                type="info"
              />

              <Button
                onClick={() => {
                  this.requestNewCode();
                }}
              >
                Get New Confirmation Code
              </Button>

              <div className="auth-options center-flex">
                <Link to="/signin">Already have an account? <span>Sign in</span></Link>
              </div>
            </>
          )}
        </div>
      </IonPage>
    );
  }
}

export default ConfirmEmail;
