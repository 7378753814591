import React from "react";

export const ActiveActionsSVG = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 6C1.09722 6 0.746528 5.85312 0.447917 5.55937C0.149306 5.26562 0 4.9125 0 4.5V1.5C0 1.09722 0.149306 0.746528 0.447917 0.447917C0.746528 0.149306 1.09722 0 1.5 0H12.5C12.9125 0 13.2656 0.149306 13.5594 0.447917C13.8531 0.746528 14 1.09722 14 1.5V4.5C14 4.9125 13.8531 5.26562 13.5594 5.55937C13.2656 5.85312 12.9125 6 12.5 6H1.5ZM1.5 14C1.09722 14 0.746528 13.8531 0.447917 13.5594C0.149306 13.2656 0 12.9125 0 12.5V9.5C0 9.09722 0.149306 8.74653 0.447917 8.44792C0.746528 8.14931 1.09722 8 1.5 8H12.5C12.9125 8 13.2656 8.14931 13.5594 8.44792C13.8531 8.74653 14 9.09722 14 9.5V12.5C14 12.9125 13.8531 13.2656 13.5594 13.5594C13.2656 13.8531 12.9125 14 12.5 14H1.5Z"
        fill="#FF7E1D"
      />
    </svg>
  );
};
