// Packages
import React from "react";
import { IonButton } from "@ionic/react";
import { observable, action } from "mobx";
import { inject, observer } from "mobx-react";
// Components
import { Link } from "react-router-dom";
import { LineChartOutlined, LoadingOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Input, message, Form } from "antd";
import StepNavigation from "../StepNavigation/StepNavigation";
// Type Definitions
import { ICheckIn } from "../../../shared/interfaces";

interface IProps {
  checkInStore?: any;
  checkIn: ICheckIn;
}

const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

@inject("checkInStore")
@observer
class StepThree extends React.Component<IProps> {
  @observable public loading = false;
  @observable public learned = this.props.checkIn.learned
    ? this.props.checkIn.learned
    : "";
  @observable public enjoyedMost = this.props.checkIn.enjoyedMost
    ? this.props.checkIn.enjoyedMost
    : "";
  @observable public futureChanges = this.props.checkIn.futureChanges
    ? this.props.checkIn.futureChanges
    : "";

  @action.bound
  public submitAnswers = async () => {
    this.loading = true;
    const resError = await this.props.checkInStore.updateCheckIn(
      this.props.checkIn.id,
      this.props.checkIn.userId,
      this.props.checkIn.status === 'step-3' ? 'complete' : 'step-3',
      this.learned,
      this.enjoyedMost,
      this.futureChanges
    );

    this.loading = false;
    if (!resError) {
      message.success("Answers logged");
    }
    return;
  };

  @action.bound
  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <IonButton color="secondary" disabled>
          <LoadingOutlined />
        </IonButton>
      );
    } else {
      return (
        <IonButton
          className="orange-button"
          color="primary"
          onClick={this.submitAnswers}
        >
          Submit
        </IonButton>
      );
    }
  }

  @action.bound
  public handleInputChange(key: string, value: any) {
    switch (key) {
      case "learned":
        this.learned = value;
        break;
      case "enjoyedMost":
        this.enjoyedMost = value;
        break;
      case "futureChanges":
        this.futureChanges = value;
        break;
      default:
        console.log("Unknown form value change");
    }
  }

  render() {
    return (
      <div className="step-three center-text">
        <h3 className="title-review">Let's reflect and plan for next week.</h3>
        <p className="subTitle-review">
          Reflecting on what you did and didn’t do helps in learning about
          oneself.
        </p>
        <div className="ci-step-three-form">
          <Form {...formItemLayout}>
            <Form.Item>
              <label className="form-title">What were the wins or highlights of your week?</label>
              <TextArea
                name="learned"
                className="learned-textarea"
                value={this.learned}
                onChange={(event: any) =>
                  this.handleInputChange("learned", event.currentTarget.value)
                }
              />
            </Form.Item>

            <Form.Item>
              <label className="form-title">What did you struggle with this week?</label>
              <TextArea
                name="enjoyedMost"
                className="enjoyed-textarea"
                value={this.enjoyedMost}
                onChange={(event: any) =>
                  this.handleInputChange(
                    "enjoyedMost",
                    event.currentTarget.value
                  )
                }
              />
            </Form.Item>

            <Form.Item>
              <label className="form-title">
              How can you improve or make next week better?
              </label>
              <TextArea
                name="futureChanges"
                className="future-changes-textarea"
                value={this.futureChanges}
                onChange={(event: any) =>
                  this.handleInputChange(
                    "futureChanges",
                    event.currentTarget.value
                  )
                }
              />
            </Form.Item>
            {/* {this.renderSubmitBtn()} */}
          </Form>
        </div>

        {/* <hr className="check-in-divider" />
        <h3>
          Based on that reflection, are there any new goals you want to set?
        </h3>
        <br /> 

        <Link to="/inspiration" target="_blank">
          <IonButton color="secondary">
            <LineChartOutlined />
            &nbsp;Find New Goals
          </IonButton>
        </Link>

        */}
        <StepNavigation
          checkIn={this.props.checkIn}
          submitAnswers={this.submitAnswers}
          title="When you're ready, mark this check-in complete!"
          description="If you missed something, you can go always go back."
          allowRevertStep
          allowIncrementStep
        />
      </div>
    );
  }
}

export default StepThree;
