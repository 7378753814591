import React from "react";

export const ActiveInspirationSVG = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 17.3346C6.54167 17.3346 6.14931 17.1714 5.82292 16.8451C5.49653 16.5187 5.33333 16.1263 5.33333 15.668H8.64583C8.64583 16.1263 8.48611 16.5187 8.16667 16.8451C7.84722 17.1714 7.45833 17.3346 7 17.3346ZM4.54167 14.918C4.30556 14.918 4.10069 14.8312 3.92708 14.6576C3.75347 14.4839 3.66667 14.2791 3.66667 14.043C3.66667 13.8069 3.75347 13.602 3.92708 13.4284C4.10069 13.2548 4.30556 13.168 4.54167 13.168H9.4375C9.67361 13.168 9.87847 13.2548 10.0521 13.4284C10.2257 13.602 10.3125 13.8069 10.3125 14.043C10.3125 14.2791 10.2257 14.4839 10.0521 14.6576C9.87847 14.8312 9.67361 14.918 9.4375 14.918H4.54167ZM3.89583 12.418C2.92361 11.8485 2.15625 11.0812 1.59375 10.1159C1.03125 9.15061 0.75 8.09853 0.75 6.95964C0.75 5.19575 1.35069 3.70616 2.55208 2.49089C3.75347 1.27561 5.23611 0.667969 7 0.667969C8.73611 0.667969 10.2083 1.27561 11.4167 2.49089C12.625 3.70616 13.2292 5.19575 13.2292 6.95964C13.2292 8.09853 12.9479 9.15061 12.3854 10.1159C11.8229 11.0812 11.0556 11.8485 10.0833 12.418H3.89583Z"
        fill="#FF7E1D"
      />
    </svg>
  );
};
