import { getUsernameFromLocalStorage, getEmailFromLocalStorage } from '../localStorage';

export const load = () => {
  window.analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY!);
}

export const page = () => {
  window.analytics.page();
}

export const track = (event: string, properties?: any) => {
  window.analytics.track(event, properties);
}

export const identify = () => {
  // username is actually the userId from AWS Cognito
  const username = getUsernameFromLocalStorage();
  const email = getEmailFromLocalStorage();
  if (username) {
    window.analytics.identify(username, { email });
  }
}