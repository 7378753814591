// Packages
import React from "react";
import { observable, computed, action, autorun } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import { track } from "../../../plugins/analytics";
import { LoadingOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert, message, Form } from "antd";
// Type Definitions
import { INewGoal } from "../../interfaces";

interface IProps {
  goalStore?: any;
  closeNewGoalModal: () => void;
}

@inject("goalStore")
@observer
class GoalForm extends React.Component<IProps> {
  @observable public loading = false;
  @observable public error = null;
  @observable public newGoal: INewGoal = {
    name: "",
    description: "",
  };

  @computed get goalTemplate() {
    return this.props.goalStore.activeGoalTemplate;
  }

  @disposeOnUnmount
  public userTokenReaction = autorun(() => {
    if (this.goalTemplate) {
      this.newGoal.name = this.goalTemplate.name;
      this.newGoal.description = this.goalTemplate.description;
      this.newGoal.templateId = this.goalTemplate.id;
    }
  });

  @action.bound
  public handleSubmit = async () => {
    this.loading = true;
    const resError = await this.props.goalStore.createGoal(this.newGoal);
    if (this.newGoal.templateId) {
      track("Goal Added From Inspiration");
    } else {
      track("Goal Added");
    }
    this.error = resError;
    this.loading = false;
    if (!this.error) {
      this.props.goalStore.createGoalModal = false;
      message.success("Goal created!");
    }
  };

  @action.bound
  public handleChange = (
    event:
      | React.FormEvent<HTMLInputElement>
      | React.FormEvent<HTMLTextAreaElement>
  ) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "name":
        this.newGoal.name = target.value;
        break;
      case "description":
        this.newGoal.description = target.value;
        break;
      default:
        console.log("Unknown form value change");
    }
  };

  public isSubmitButtonDisabled = () => {
    return this.newGoal.name === undefined || this.newGoal.name === "";
  };

  public renderSubmitBtn = () => {
    if (this.loading) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button"
          disabled
        >
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button"
          disabled={this.isSubmitButtonDisabled()}
        >
          Create goal
        </Button>
      );
    }
  };

  render() {
    const { TextArea } = Input;
    return (
      <>
        <div className="goal-form-wrapper">
        <div className="error-container">
          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}
          </div>
          <h3 className="montX XL">New goal</h3>

          <Form onFinish={this.handleSubmit} className="goal-form">
            <Form.Item>
              <label className="label" htmlFor="name">
                Name*
              </label>
              <Input
                name="name"
                placeholder="Keep it simple"
                value={this.newGoal.name}
                onChange={this.handleChange}
                required
              />
            </Form.Item>
            <Form.Item>
              <label className="label" htmlFor="description">
                Description
              </label>
              <TextArea
              style={{minHeight: "90px",resize: "none"}}
                name="description"
                placeholder="Add some information about the goal."
                value={this.newGoal.description}
                onChange={this.handleChange}
              />
            </Form.Item>
            <div className="form-buttons-wrapper">
              <Button
                className="cancel-button"
                onClick={this.props.closeNewGoalModal}
              >
                Cancel
              </Button>
              {this.renderSubmitBtn()}
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default GoalForm;
