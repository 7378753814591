import React from "react";

type Props = {
  color?: string;
};

export const CrossSVG = ({ color }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00035 8.76777L2.58093 13.1872C2.33049 13.4376 2.03587 13.5628 1.69704 13.5628C1.35822 13.5628 1.06359 13.4376 0.813161 13.1872C0.562727 12.9368 0.43751 12.6421 0.43751 12.3033C0.43751 11.9645 0.562727 11.6699 0.813161 11.4194L5.23258 7L0.813161 2.58058C0.562727 2.33015 0.43751 2.03552 0.43751 1.6967C0.43751 1.35788 0.562727 1.06325 0.813161 0.812816C1.06359 0.562382 1.35822 0.437165 1.69704 0.437165C2.03587 0.437166 2.33049 0.562382 2.58093 0.812816L7.00035 5.23223L11.4198 0.812816C11.6702 0.562383 11.9648 0.437164 12.3036 0.437165C12.6425 0.437165 12.9371 0.562382 13.1875 0.812816C13.438 1.06325 13.5632 1.35788 13.5632 1.6967C13.5632 2.03552 13.438 2.33015 13.1875 2.58058L8.76811 7L13.1875 11.4194C13.438 11.6699 13.5632 11.9645 13.5632 12.3033C13.5632 12.6421 13.438 12.9368 13.1875 13.1872C12.9371 13.4376 12.6425 13.5628 12.3036 13.5628C11.9648 13.5628 11.6702 13.4376 11.4198 13.1872L7.00035 8.76777Z"
        fill={color ? color : "#2B2930"}
      />
    </svg>
  );
};
