import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export default function FlagSVG({ className, color }: Props) {
  return (
    <svg
      style={{minWidth: '15px'}}
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17C0.716667 17 0.479167 16.9042 0.2875 16.7125C0.0958333 16.5208 0 16.2833 0 16V1C0 0.716667 0.0958333 0.479167 0.2875 0.2875C0.479167 0.0958333 0.716667 0 1 0H8.175C8.40833 0 8.61667 0.075 8.8 0.225C8.98333 0.375 9.1 0.566667 9.15 0.8L9.4 2H14C14.2833 2 14.5208 2.09583 14.7125 2.2875C14.9042 2.47917 15 2.71667 15 3V11C15 11.2833 14.9042 11.5208 14.7125 11.7125C14.5208 11.9042 14.2833 12 14 12H8.825C8.59167 12 8.38333 11.925 8.2 11.775C8.01667 11.625 7.9 11.4333 7.85 11.2L7.6 10H2V16C2 16.2833 1.90417 16.5208 1.7125 16.7125C1.52083 16.9042 1.28333 17 1 17Z"
        className={className}
        fill={color ? color : "#FF7E1D"}
      />
    </svg>
  );
}
