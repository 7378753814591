import { IonSpinner } from "@ionic/react";
import { Auth } from "aws-amplify";
import { autorun } from "mobx";
import { disposeOnUnmount, inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps {
  userStore?: any;
}

@inject("userStore")
@observer
class CallBack extends React.Component<IProps> {
  @disposeOnUnmount
  public checkInAutorun = autorun(async () => {
    setTimeout(async () => {
      const res = await Auth.currentUserInfo();
      if (res) window.location.href = "/goals";
      else window.location.href = "/";
    }, 3000);
  });

  render(): React.ReactNode {
    return (
      <div className="dashboard-column">
        <IonSpinner className="loading" />
      </div>
    );
  }
}

export default CallBack;
