import React from "react";

export const FlagNotFilledSVG = ({color}:{
  color?: string
}) => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M1.04199 14.4987C0.805881 14.4987 0.60102 14.4119 0.427409 14.2383C0.253798 14.0647 0.166992 13.8606 0.166992 13.6259V1.2048C0.166992 0.970177 0.253798 0.766059 0.427409 0.592448C0.60102 0.418837 0.805881 0.332031 1.04199 0.332031H7.02116C7.22558 0.332031 7.40809 0.396483 7.5687 0.525386C7.72931 0.654288 7.83152 0.819003 7.87533 1.01953L8.07774 1.97786H11.8021C12.0315 1.97786 12.233 2.06467 12.4066 2.23828C12.5802 2.41189 12.667 2.61742 12.667 2.85486V9.5592C12.667 9.79664 12.5802 10.0022 12.4066 10.1758C12.233 10.3494 12.0276 10.4362 11.7904 10.4362H7.45866C7.25424 10.4362 7.07173 10.3717 6.91112 10.2428C6.75051 10.1139 6.6483 9.94923 6.60449 9.7487L6.40418 8.76953H1.91699V13.6333C1.91699 13.863 1.83019 14.0647 1.65658 14.2383C1.48296 14.4119 1.2781 14.4987 1.04199 14.4987ZM8.17607 8.6862H10.917V3.72786H6.62533L6.31283 2.08203H1.91699V7.01953H7.8488L8.17607 8.6862Z"
        fill={color ? color : "#4B4A4D"}
      />
    </svg>
  );
};
