import React from "react";

const ActiveReviewNReflectSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9375 9.875L4.6875 8.64583C4.53472 8.49306 4.36111 8.41667 4.16667 8.41667C3.97222 8.41667 3.79861 8.49306 3.64583 8.64583C3.49306 8.79861 3.41667 8.97569 3.41667 9.17708C3.41667 9.37847 3.49306 9.55556 3.64583 9.70833L5.41667 11.4792C5.56439 11.6319 5.73674 11.7083 5.93371 11.7083C6.13068 11.7083 6.30556 11.6319 6.45833 11.4792L10.3542 7.58333C10.5069 7.43056 10.5833 7.25347 10.5833 7.05208C10.5833 6.85069 10.5069 6.67361 10.3542 6.52083C10.2014 6.36806 10.0278 6.29167 9.83333 6.29167C9.63889 6.29167 9.46528 6.36806 9.3125 6.52083L5.9375 9.875ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3.5C0 3.0875 0.146875 2.73438 0.440625 2.44063C0.734375 2.14688 1.0875 2 1.5 2H5.0625C5.17361 1.56944 5.40625 1.21181 5.76042 0.927083C6.11458 0.642361 6.52778 0.5 7 0.5C7.47222 0.5 7.88542 0.642361 8.23958 0.927083C8.59375 1.21181 8.82639 1.56944 8.9375 2H12.5C12.9125 2 13.2656 2.14688 13.5594 2.44063C13.8531 2.73438 14 3.0875 14 3.5V14.5C14 14.9125 13.8531 15.2656 13.5594 15.5594C13.2656 15.8531 12.9125 16 12.5 16H1.5ZM7 3C7.14444 3 7.26389 2.95278 7.35833 2.85833C7.45278 2.76389 7.5 2.64444 7.5 2.5C7.5 2.35556 7.45278 2.23611 7.35833 2.14167C7.26389 2.04722 7.14444 2 7 2C6.85556 2 6.73611 2.04722 6.64167 2.14167C6.54722 2.23611 6.5 2.35556 6.5 2.5C6.5 2.64444 6.54722 2.76389 6.64167 2.85833C6.73611 2.95278 6.85556 3 7 3Z"
        fill="#FF7E1D"
      />
    </svg>
  );
};

export default ActiveReviewNReflectSVG;
