// Packages
import React from "react";
import { IonPage } from "@ionic/react";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import { LoadingOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert } from "antd";
// Plugin Functions
import { authForgotPassword } from "../../plugins/auth/auth";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  userStore: any;
}

@inject("userStore")
@observer
class ForgotPassword extends React.Component<IProps> {
  @observable public error = null;
  @observable public loading = false;

  @computed get email() {
    return this.props.userStore.proposedUserEmail;
  }

  public handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resError = await authForgotPassword(this.props, this.email);
    // If page didn't navigate, an error was returned
    this.error = resError;
    this.loading = false;
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.userStore.proposedUserEmail = event.currentTarget.value;
  };

  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
        >
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button
          type="primary"
          htmlType="submit"
          className="form-submit-button sign-in-button"
        >
          Submit
        </Button>
      );
    }
  }

  render() {
    return (
      <IonPage className="ion-padding">
        <div className="center-text form-container m-y-auto">
          <h1 className="sign-in-header">Forgot password</h1>
          <p className="sign-in-subheader m-x-auto" style={{ width: "55%" }}>
            We will send you an <b>email</b> to help you reset your{" "}
            <b>password</b>
          </p>
          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}

          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item className="form-item">
              <label htmlFor="email" id="emailLabel" className="label-sign-in">
                Email
              </label>
              <Input
                className="input-sign-in"
                id="email"
                name="email"
                prefix={<MailOutlined />}
                placeholder="Email"
                value={this.email}
                onChange={this.handleChange}
              />
            </Form.Item>
            <div className="m-x-auto">{this.renderSubmitBtn()}</div>
          </Form>
        </div>
      </IonPage>
    );
  }
}

export default ForgotPassword;
