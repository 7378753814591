// Packages
import React from "react";
import { IonButton } from "@ionic/react";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { FileDoneOutlined } from "@ant-design/icons";
// Components
import { Result, Divider, Collapse } from "antd";
import { Link } from "react-router-dom";
import StepNavigation from "../StepNavigation/StepNavigation";
// Type Definitions
import { ICheckIn } from "../../../shared/interfaces";
// Styles
import "./StepsComplete.scss";
import { TickMarkSVG } from "../../../components/svgComponents/TickMarkSVG";
import { ResultsSVG } from "../../../components/svgComponents/ResultsSVG";

interface IProps {
  checkInStore?: any;
  checkIn: ICheckIn;
}

const { Panel } = Collapse;

@inject("checkInStore")
@observer
class StepsComplete extends React.Component<IProps> {
  @observable public learned = this.props.checkIn.learned
    ? this.props.checkIn.learned
    : "No answer provided";
  @observable public enjoyedMost = this.props.checkIn.enjoyedMost
    ? this.props.checkIn.enjoyedMost
    : "No answer provided";
  @observable public futureChanges = this.props.checkIn.futureChanges
    ? this.props.checkIn.futureChanges
    : "No answer provided";

  @computed get completionDate() {
    return moment()
      .calendar(this.props.checkIn.updatedAt, { sameElse: "on MM/DD/YYYY" })
      .toLowerCase();
  }

  render() {
    return (
      <div className="steps-complete">
        <Result
          status="success"
          icon={<TickMarkSVG />}
          style={{ background: "#FFC773", borderRadius: "0.5rem" }}
          title="Check-in complete!"
          subTitle={`You completed this check-in ${this.completionDate}`}
          extra={[
            <div className="flexBoxRow justify-content-center" key={0}>
              <Link to="/actions" key="actions">
                <IonButton color="primary">Go to Actions</IonButton>
              </Link>
              <Link to="/inspiration" key="inspiration">
                <IonButton color="none" className="get-inspired" key="buy">
                  Get Inspired
                </IonButton>
              </Link>
            </div>,
          ]}
        />
        <div className="flexBoxRow" style={{ gap: "6px", marginTop: "1rem" }}>
          <ResultsSVG /> REFLECTIONS
        </div>

        <div className="check-in-results">
          <div className="check-in-result">
            <div className="check-in-title">
              What were the wins or highlights of your week?
            </div>
            <div className="check-in-description">{this.learned}</div>
          </div>
          <div className="check-in-result">
            <div className="check-in-title">
              What did you struggle with this week?
            </div>
            <div className="check-in-description">{this.enjoyedMost}</div>
          </div>
          <div className="check-in-result">
            <div className="check-in-title">
              How can you improve or make next week better?
            </div>
            <div className="check-in-description">{this.futureChanges}</div>
          </div>
          {/* <Collapse defaultActiveKey={["learned"]}>
            <Panel header="What did you learn?" key="learned">
              <p>{this.learned}</p>
            </Panel>
            <Panel header="What did you enjoy most?" key="enjoyedMost">
              <p>{this.enjoyedMost}</p>
            </Panel>
            <Panel
              header="What would you do differently next time?"
              key="futureChanges"
            >
              <p>{this.futureChanges}</p>
            </Panel>
          </Collapse> */}
        </div>

        <StepNavigation
          checkIn={this.props.checkIn}
          title="Made a mistake?"
          description="Feel free to revert changes and come back."
          allowRevertStep
        />
      </div>
    );
  }
}

export default StepsComplete;
