import React from "react";

export default function EditSVG() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 13.7487H3.47917L10.4792 6.7487L9.25 5.51953L2.25 12.5195V13.7487ZM14.1875 5.54036L10.4583 1.8112L11.4792 0.790365C11.8264 0.443142 12.2396 0.269531 12.7188 0.269531C13.1979 0.269531 13.6111 0.443142 13.9583 0.790365L15.2083 2.04036C15.5417 2.3737 15.7083 2.78689 15.7083 3.27995C15.7083 3.773 15.5417 4.1862 15.2083 4.51953L14.1875 5.54036ZM1.375 15.4987C1.13889 15.4987 0.934028 15.4119 0.760417 15.2383C0.586806 15.0647 0.5 14.8598 0.5 14.6237V12.1237C0.5 12.0126 0.520833 11.9049 0.5625 11.8008C0.604167 11.6966 0.666667 11.6029 0.75 11.5195L9.22917 3.04036L12.9583 6.76953L4.47917 15.2487C4.39583 15.332 4.30208 15.3945 4.19792 15.4362C4.09375 15.4779 3.98611 15.4987 3.875 15.4987H1.375Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}