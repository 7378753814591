import { observable, action } from 'mobx';

export class AppStore {
  // Observables
  @observable serviceWorkerRegistration:ServiceWorkerRegistration | null = null;

  // Actions
  @action.bound
  public updateServiceWorker(swRegistration: ServiceWorkerRegistration) {
    this.serviceWorkerRegistration = swRegistration;
  }
}

export default new AppStore();