import { IActivity } from '../../shared/interfaces';

export const activityComparator = (a: IActivity, b: IActivity) => {
  if (a.status === b.status ) {
    if (a.type === "routine" && a.frequency && b.frequency) {
      // sort by frequency
      if (a.frequency < b.frequency) {
        return 1;
      }
      if (a.frequency > b.frequency) {
        return -1;
      }
      return 0;
    } else if (a.type === "task" && a.completionDate && b.completionDate) { 
      if (a.completionDate < b.completionDate) {
        return -1;
      }
      if (a.completionDate > b.completionDate) {
        return 1;
      }
      return 0;
    }
  }
  // sort by status - push completed activies to the bottom
  return a.status === 'incomplete' ? -1 : 1
}