import React from "react";

export const TickMarkSVG = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_325_21812"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="41"
        height="40"
      >
        <rect x="0.5" width="40" height="40" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_325_21812)">
        <path
          d="M18.083 27.6098L29.8608 15.832L27.8052 13.8042L18.083 23.5265L13.1663 18.6098L11.1385 20.6376L18.083 27.6098ZM20.4997 36.6654C18.2126 36.6654 16.0552 36.2279 14.0275 35.3529C11.9997 34.4779 10.2312 33.2857 8.72188 31.7765C7.21263 30.2672 6.02051 28.4987 5.14551 26.4709C4.27051 24.4431 3.83301 22.2857 3.83301 19.9987C3.83301 17.6931 4.27051 15.5265 5.14551 13.4987C6.02051 11.4709 7.21263 9.70703 8.72188 8.20703C10.2312 6.70703 11.9997 5.51953 14.0275 4.64453C16.0552 3.76953 18.2126 3.33203 20.4997 3.33203C22.8052 3.33203 24.9719 3.76953 26.9997 4.64453C29.0275 5.51953 30.7913 6.70703 32.2913 8.20703C33.7913 9.70703 34.9788 11.4709 35.8538 13.4987C36.7288 15.5265 37.1663 17.6931 37.1663 19.9987C37.1663 22.2857 36.7288 24.4431 35.8538 26.4709C34.9788 28.4987 33.7913 30.2672 32.2913 31.7765C30.7913 33.2857 29.0275 34.4779 26.9997 35.3529C24.9719 36.2279 22.8052 36.6654 20.4997 36.6654Z"
          fill="#2B2930"
        />
      </g>
    </svg>
  );
};
