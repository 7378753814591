// Packages
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonPopover,
  IonList,
  IonButton,
  IonIcon,
} from "@ionic/react";
import React from "react";
import { action, computed } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";
// Components
import { Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
// Styles
import "./GoalCompleteCard.scss";
// Type Definitions
import { IActivity, IGoal } from "../../../shared/interfaces";
import { ellipsisVertical } from "ionicons/icons";
import TeamsSVG from "../../../components/svgComponents/TeamSVG";
import EditSVG from "../../../components/svgComponents/EditSVG";
import DeleteSVG from "../../../components/svgComponents/DeleteSVG";

interface IProps {
  goal: IGoal;
  goalStore?: any;
  classNameForWrapper: string;
}

@inject("goalStore")
@observer
class GoalCompleteCard extends React.Component<IProps> {
  @computed get readableDate() {
    return moment(
      this.props.goal.completedOn
        ? this.props.goal.completedOn
        : this.props.goal.updatedAt,
      "YYYY-MM-DD hh:mm:ss"
    ).calendar("", {
      sameDay: "[today]",
      lastDay: "[yesterday]",
      sameElse: "MM/DD/YYYY",
    });
    // .format('MM/DD/YYYY')
    // .toLowerCase()
  }

  @computed get completedText() {
    return `Completed ${this.readableDate}`;
  }

  @action.bound
  public decompleteGoal = () => {
    const goal = {
      ...this.props.goal,
      status: "incomplete",
    };

    this.props.goalStore.updateGoal(goal, 1).then((err: string) => {
      if (!err) {
        message.destroy();
        message.info("Goal moved back to active list");
      }
    });
  };

  @action.bound
  public editGoal = () => {
    this.props.goalStore.activeGoal = this.props.goal;
    this.props.goalStore.updateGoalModal = true;
  };

  @action.bound
  public async resetGoalActivities() {
    const key = "deleteGoalMessage";
    message.loading({ content: "Removing goal...", key });
    const action = "refetchCompleteGoals";
    const resetActivitiesError =
      await this.props.goalStore.disconnectOrDeleteGoal(
        this.props.goal.id,
        true,
        action
      );

    if (resetActivitiesError) {
      message.error({
        content: "Error removing goal. Please try again later.",
        key,
        duration: 2,
      });
    }
  }

  render() {
    //First Line under return: passing specific wrapper alternate between styling with and without margins - alternating fixes masonry 2 card bug
    return (
      <Link
        to={`/goals/${this.props.goal.id}`}
        className={this.props.classNameForWrapper}
        onClick={() => {
          this.props.goalStore.activeGoal = this.props.goal;
        }}
      >
        <IonCard className="completed-goal-card">
          <IonCardHeader className="card-header">
            <div className="card-menu-title-completed">
              <IonCardTitle className="no-margin">
                <h2 className="title">{this.props.goal.name}</h2>
              </IonCardTitle>
              <div className="">
                <div
                  className="kebab-Menu flexBoxRow controls-container"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {(this.props.goal.teamId || this.props.goal.mentorId) && (
                    <Popconfirm
                      className="delete-popover"
                      title="Are you sure you want to disconnect this goal?"
                      onConfirm={() =>
                        this.props.goalStore.disconnectGoal(this.props.goal.id)
                      }
                      okText="Disconnect goal"
                      cancelText="Cancel"
                    >
                      <button className="shared">
                        <TeamsSVG color="#323232" />
                      </button>
                    </Popconfirm>
                  )}
                  <div className="menu-container">
                    <KebabMenu
                      editGoal={this.editGoal}
                      resetGoalActivities={this.resetGoalActivities}
                      // goToGoalDetails={this.goToGoalDetails}
                      completeGoal={this.decompleteGoal}
                      goal={this.props.goal}
                      activityStore={this.props.goalStore}
                    />
                  </div>
                </div>
              </div>
              {/* <Popconfirm
                  title="Want to keep working on this goal?"
                  onConfirm={this.decompleteGoal}
                  okText="Mark incomplete"
                  cancelText="Nevermind"
                >
                  <UndoOutlined title="Mark incomplete" className="icon-button completed" />
                </Popconfirm>
                <EditOutlined onClick={() => this.editGoal()} className="icon-button completed" />
                <Popconfirm
                  title="Are you sure you want to delete this goal?"
                  onConfirm={this.archiveGoal}
                  okText="Delete goal"
                  cancelText="Keep at it"
                >
                  <DeleteOutlined className="icon-button completed" />
                </Popconfirm> */}
            </div>
            {/* <span className="mont M">COMPLETED GOAL</span> */}
          </IonCardHeader>
          <IonCardContent>
            {this.props.goal.description && (
              <p className="goal-card-descr">{this.props.goal.description}</p>
            )}

            <div className="card-links">
              <p className="goal-completed-on" title={this.completedText}>
                {this.completedText}
              </p>

              {/* <Link
                to={`/goals/${this.props.goal.id}`}
                className="text-button completed"
              >
                View History
              </Link> */}
            </div>
          </IonCardContent>
        </IonCard>
      </Link>
    );
  }
}

export default GoalCompleteCard;

interface KebabMenuProps {
  editGoal: () => void;
  resetGoalActivities: () => void;
  //goToGoalDetails: () => void;
  completeGoal: () => void;
  goal: IGoal;
  activityStore: any;
}

interface KebabMenuState {
  showPopover: boolean;
  event?: Event;
}

class KebabMenu extends React.Component<KebabMenuProps, KebabMenuState> {
  constructor(props: KebabMenuProps) {
    super(props);
    this.state = {
      showPopover: false,
    };
  }

  // public hasIncompletedActivities() {
  //   const { id } = this.props.goal;
  //   const { tasks, routines } = this.props.activityStore;
  //   const hasIncompletedTasks = tasks.some(
  //     (task: IActivity) => task.goalId === id
  //   );
  //   const hasIncompletedRoutines = routines.some(
  //     (routine: IActivity) => routine.goalId === id
  //   );
  //   return hasIncompletedRoutines || hasIncompletedTasks;
  // }

  public checkmarkIcon() {
    // return (
    //   <Popconfirm
    //     title="You have uncompleted tasks and/or routines for this goal. Please take a second to either delete or complete them"
    //     onConfirm={() => this.props.goToGoalDetails()}
    //     okText="Okay"
    //     cancelText="Cancel"
    //   >
    //     <CheckOutlined title="Mark completed" className="icon-button" />
    //   </Popconfirm>
    // );
    return (
      <CheckOutlined
        onClick={() => this.props.completeGoal()}
        title="Mark completed"
        className="icon-button"
      />
    );
  }

  handlePopoverOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    this.setState({ showPopover: true, event: e.nativeEvent });
  };

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };

  handleEditGoalClick = () => {
    this.props.editGoal();
    this.setState({ showPopover: false });
  };

  handleResetGoalActivitiesClick = () => {
    this.props.resetGoalActivities();
    this.setState({ showPopover: false });
  };

  render() {
    return (
      <>
        <IonPopover
          isOpen={this.state.showPopover}
          event={this.state.event}
          onDidDismiss={this.handlePopoverClose}
        >
          <IonList className="small-width">
            <IonButton
              onClick={(e) => {
                e.preventDefault();
                this.handleEditGoalClick();
              }}
              expand="full"
              className="no-background"
            >
              <div className="edit">
                <EditSVG /> Edit
              </div>
            </IonButton>
            <Popconfirm
              className="delete-popover"
              title="Are you sure you want to delete this goal?"
              onConfirm={this.handleResetGoalActivitiesClick}
              okText="Delete goal"
              cancelText="Keep at it"
            >
              <IonButton
                expand="full"
                className="no-background"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="delete">
                  <DeleteSVG />
                  Delete
                </div>
              </IonButton>
            </Popconfirm>
          </IonList>
        </IonPopover>
        <button
          className="menu-button"
          onClick={(e: any) => this.handlePopoverOpen(e)}
        >
          <IonIcon icon={ellipsisVertical} />
        </button>
      </>
    );
  }
}
