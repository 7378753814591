import React from "react";

const ArrowUpSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00011 3.82969L2.10011 8.72969C1.90011 8.92969 1.66678 9.02552 1.40011 9.01719C1.13344 9.00885 0.900109 8.90469 0.700109 8.70469C0.516776 8.50469 0.420943 8.27135 0.412609 8.00469C0.404276 7.73802 0.500109 7.50469 0.700109 7.30469L7.30011 0.704688C7.40011 0.604688 7.50844 0.533854 7.62511 0.492188C7.74178 0.450521 7.86678 0.429688 8.00011 0.429688C8.13344 0.429688 8.25844 0.450521 8.37511 0.492188C8.49178 0.533854 8.60011 0.604688 8.70011 0.704688L15.3001 7.30469C15.4834 7.48802 15.5751 7.71719 15.5751 7.99219C15.5751 8.26719 15.4834 8.50469 15.3001 8.70469C15.1001 8.90469 14.8626 9.00469 14.5876 9.00469C14.3126 9.00469 14.0751 8.90469 13.8751 8.70469L9.00011 3.82969V15.0047C9.00011 15.288 8.90428 15.5255 8.71261 15.7172C8.52094 15.9089 8.28344 16.0047 8.00011 16.0047C7.71678 16.0047 7.47928 15.9089 7.28761 15.7172C7.09594 15.5255 7.00011 15.288 7.00011 15.0047V3.82969Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowUpSVG;
