// Packages
import React from "react";
import { IonModal, IonPage } from "@ionic/react";
import { autorun, action, computed, observable } from "mobx";
import { inject, observer, disposeOnUnmount } from "mobx-react";
import _ from "lodash";
import moment from "moment";
// import { BulbOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
// Components
import { Card, Alert, notification } from "antd";
import EditActivityForm from "../../../shared/forms/EditActivityForm/EditActivityForm";
import GoalReviewCard from "../GoalReviewCard/GoalReviewCard";
import ActivityCard from "../../../shared/cards/ActivityCard/ActivityCard";
import StepNavigation from "../StepNavigation/StepNavigation";
// Type Definitions
import { ICheckIn, IGoal, IActivity } from "../../../shared/interfaces";
import EditGoalForm from "../../../shared/forms/EditGoalForm/EditGoalForm";
import { track } from "../../../plugins/analytics";
import ActivityForm from "../../../shared/forms/ActivityForm/ActivityForm";
interface IProps {
  checkInStore?: any;
  activityStore?: any;
  goalStore?: any;
  checkIn: ICheckIn;
  changeSkipToCheckIn: () => void;
}

@inject("checkInStore", "activityStore", "goalStore")
@observer
class StepOne extends React.Component<IProps> {
  @observable public loadingCount = 0;
  @observable public showSkeleton = true;

  @computed get goalsWorked() {
    return this.props.checkInStore.activeCheckIn.data;
  }

  @computed get independentActivities() {
    return this.props.checkInStore.independentActivities;
    // return this.props.activityStore.activities.filter((activity: IActivity) => {
    //   return activity.goalId === "0";
    // });
  }

  @action.bound
  public closeNewActivityModal() {
    this.props.activityStore.createActivityModal = false;
  }

  @disposeOnUnmount
  public stepOneAutorun = autorun(() => {
    // this.getActivities();
    // this.getGoals();
    if (this.goalsWorked) {
      this.loadingCount = 4;
      this.showSkeleton = false;
    }
  });

  @action.bound
  public closeGoalModal() {
    this.props.goalStore.updateGoalModal = false;
  }
  @action.bound
  incrementStep() {
    this.props.checkInStore.incrementStep();
  }

  @action.bound
  public updateCheckIn = (goalId: string, value: any) => {
    this.props.checkInStore.activeCheckIn.data =
      this.props.checkInStore.activeCheckIn.data.map((item: any) => {
        if (item.id === goalId) {
          if (value.type === "task") {
            item.tasks = [...item.tasks, value].flat(2);
          } else if (value.type === "routine") {
            item.routines = [...item.routines, value].flat(2);
          }
        }
        return item; // Ensure a value is returned at the end
      });
  };

  public renderNullExplainer = () => {
    if (
      this.loadingCount > 2 &&
      this.goalsWorked &&
      this.goalsWorked.length < 1
    ) {
      return (
        <div className="center-text">
          <Alert
            message="No goals found for this week. Proceed to the next step to review."
            type="info"
            showIcon
            className="inline-alert center"
          />
        </div>
      );
    }
  };

  public renderLoading = () => {
    if (this.loadingCount < 3 || this.showSkeleton) {
      return (
        <div className="check-in-card-wrapper">
          <Card className="goal-review-card" loading />
        </div>
      );
    }
  };

  public renderExtraActivities = () => {
    if (this.independentActivities.length > 0) {
      return (
        <div style={{ marginTop: "2rem" }}>
          <div className="center-text">
            <h3 className="step-title">
              And here is everything else you worked on
            </h3>
            <p className="step-description">
              Showing task & routines without goal assigned to them.
            </p>
          </div>

          <div className="check-in-card-wrapper" key="independent-activities">
            {this.independentActivities.map((activity: IActivity) => {
              return (
                <ActivityCard
                  key={activity.id}
                  activity={activity}
                  goalName="Independent Activity"
                  historicLookup={this.props.checkIn.endDate}
                  completionType={activity.completionType}
                />
              );
            })}
            <hr />
          </div>
        </div>
      );
    }
  };

  @action.bound
  public closeActivityModal() {
    this.props.activityStore.updateActivityModal = false;
  }

  render() {
    return (
      <div className="step-one">
        <div className="center-text">
          <h3 className="title-review">
            Here are the goals you worked on on this week
          </h3>
          <p className="subTitle-review">
            Take a minute to mark progress, add new actions, or complete goals
          </p>
        </div>
        {this.renderNullExplainer()}
        {this.renderLoading()}
        {this.goalsWorked &&
          this.showSkeleton === false &&
          this.goalsWorked.map((goal: IGoal) => {
            return (
              <div className="check-in-card-wrapper" key={goal.id}>
                <GoalReviewCard
                  goal={goal}
                  loading={this.loadingCount < 3}
                  startDate={this.props.checkIn.startDate}
                  endDate={this.props.checkIn.endDate}
                />
              </div>
            );
          })}

        {this.renderExtraActivities()}

        <StepNavigation
          checkIn={this.props.checkIn}
          title="When you're ready, head over to next step"
          description="If you missed something, you can go always go back."
          allowIncrementStep
        />

        <IonModal
          cssClass={
            this.props.activityStore.createActivityType === "task"
              ? "activity-modal"
              : "routine-modal"
          }
          isOpen={this.props.activityStore.createActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <ActivityForm
              closeNewActivityModal={this.closeNewActivityModal}
              updateCheckIn={this.updateCheckIn}
            />
          </div>
        </IonModal>

        <IonModal
          cssClass={
            this.props.activityStore.activeActivity.type === "task"
              ? "activity-modal"
              : "routine-modal"
          }
          isOpen={this.props.activityStore.updateActivityModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <EditActivityForm closeActivityModal={this.closeActivityModal} />
          </div>
        </IonModal>

        <IonModal
          cssClass={
            this.props.goalStore.activeGoal.status === "complete"
              ? "goal-modal-complete"
              : "goal-modal"
          }
          isOpen={this.props.goalStore.updateGoalModal}
          border-radius="7"
          backdropDismiss={false}
        >
          <div className="ion-padding modal-content overflow-y-scroll">
            <EditGoalForm closeGoalModal={this.closeGoalModal} />
          </div>
        </IonModal>
      </div>
    );
  }
}

export default StepOne;
