//Packages
import React from "react";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { BarChartOutlined, SyncOutlined } from "@ant-design/icons";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import moment from "moment";
import { IonSpinner, IonCardSubtitle } from "@ionic/react";
//Styles
import "./RoutineProgress.scss";
//Type Definitions
import { IActivity } from "../interfaces";

interface IProps {
  activityStore?: any;
  activity?: IActivity;
}

@inject("activityStore")
@observer
class RoutineProgress extends React.Component<IProps> {
  @observable public loading = false;
  @observable public error = null;
  @observable public chartData: object[] = [];
  @observable public pageOneData: object[] = [];

  mapRoutineInstances = () => {
    const chartDates = Object.keys(
      this.props.activityStore.activeRoutine.history
    );
    const historyLog = this.props.activityStore.activeRoutine.history;
    chartDates.map((date) => {
      const currentLog = {
        name: moment(date).format("MM/DD/YYYY"),
        Target: historyLog[date].frequency,
        Completed: historyLog[date].timesCompleted,
        //Target and Completed are capitalized here because Recharts pulls these into the chart as labels for the legend (see datakeys below)
      };
      this.chartData.push(currentLog);
      return this.chartData;
    });
  };

  sortData(chartData: any) {
    return chartData.sort(this.sortObjs);
  }

  sortObjs(a: IActivity, b: IActivity) {
    const timeA = new Date(a.name).getTime();
    const timeB = new Date(b.name).getTime();
    return timeA - timeB;
  }

  mapPageOne = () => {
    const sortedData = this.sortData(this.chartData);
    const pageOne = sortedData.slice(-4);
    return pageOne;
  };

  render() {
    if (!!this.props.activityStore.activeRoutine.history === false) {
      return (
        <div className="center-text">
          <br />
          <h1 className="mont XL">Routine Progress</h1>
          {this.loading ? (
            <IonSpinner />
          ) : (
            !!this.props.activityStore.activeRoutine.history
          )}
          <IonCardSubtitle className="mont M">
            <SyncOutlined /> {this.props.activityStore.activeRoutine.name}
          </IonCardSubtitle>
          <BarChartOutlined className="bar-chart-icon" />
          <p className="open-sans">
            Not enough data available to display in a chart
          </p>
        </div>
      );
    } else if (!!this.props.activityStore.activeRoutine.history === true) {
      this.mapRoutineInstances();
      const mapResults = this.mapPageOne();
      return (
        <div className="center-text">
          <br />
          <h1 className="mont XL">Routine Progress</h1>
          {this.loading ? (
            <IonSpinner />
          ) : (
            !!this.props.activityStore.activeRoutine.history
          )}
          <IonCardSubtitle className="mont M">
            <SyncOutlined /> {this.props.activityStore.activeRoutine.name}
          </IonCardSubtitle>
          {this.loading ? <IonSpinner /> : null}
          <div className="open-sans">
            <ComposedChart
              width={500}
              height={380}
              data={mapResults}
              margin={{ top: 20, right: 20, bottom: 30, left: 30 }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="name"
                label={{
                  value: "Week Ending On",
                  position: "bottom",
                  offset: 5,
                }}
              />
              <YAxis
                label={{
                  value: "Times Per Week",
                  angle: -90,
                  viewBox: { x: 35, y: 150, width: 15, height: 100 },
                }}
              />
              <Tooltip />
              <Bar
                dataKey="Completed"
                barSize={20}
                fill="var(--ion-color-tertiary)"
              />
              <Line
                type="monotone"
                dataKey="Target"
                stroke="var(--ion-color-primary)"
              />
              <Legend
                verticalAlign="bottom"
                height={36}
                wrapperStyle={{ bottom: 0, left: 65 }}
              />
            </ComposedChart>
          </div>
        </div>
      );
    }
  }
}

export default RoutineProgress;
