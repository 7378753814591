import React from "react";

const ShareSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        d="M13 12.1247C12.6633 12.1246 12.3301 12.1927 12.0204 12.3249C11.7108 12.4572 11.4311 12.6509 11.1984 12.8942L5.41719 9.64148C5.52918 9.22094 5.52918 8.77842 5.41719 8.35788L11.1984 5.10515C11.6194 5.54161 12.1851 5.80961 12.7895 5.85893C13.3939 5.90825 13.9955 5.73552 14.4817 5.37309C14.9679 5.01066 15.3052 4.4834 15.4306 3.89009C15.5559 3.29678 15.4606 2.67813 15.1626 2.15004C14.8645 1.62194 14.3841 1.22064 13.8114 1.0213C13.2387 0.821971 12.613 0.838288 12.0514 1.0672C11.4899 1.29611 11.0311 1.7219 10.761 2.26481C10.4908 2.80773 10.4279 3.4305 10.584 4.01648L4.80274 7.26921C4.45741 6.90915 4.01224 6.66064 3.52451 6.55566C3.03678 6.45067 2.52879 6.49402 2.0659 6.68012C1.60301 6.86622 1.20639 7.18655 0.927049 7.59992C0.647713 8.01329 0.498444 8.50078 0.498444 8.99968C0.498444 9.49858 0.647713 9.98607 0.927049 10.3994C1.20639 10.8128 1.60301 11.1331 2.0659 11.3192C2.52879 11.5053 3.03678 11.5487 3.52451 11.4437C4.01224 11.3387 4.45741 11.0902 4.80274 10.7301L10.584 13.9829C10.4499 14.4875 10.4773 15.0215 10.6621 15.5098C10.847 15.9981 11.1801 16.4163 11.6147 16.7056C12.0493 16.995 12.5636 17.141 13.0855 17.1232C13.6073 17.1054 14.1105 16.9247 14.5243 16.6063C14.9382 16.288 15.242 15.8481 15.3932 15.3483C15.5443 14.8485 15.5352 14.314 15.367 13.8197C15.1989 13.3253 14.8802 12.8961 14.4557 12.5921C14.0312 12.2881 13.5221 12.1246 13 12.1247Z"
        fill="white"
      />
    </svg>
  );
};

export default ShareSVG;
