import React from "react";

type DeleteSVGProps = {
  color?: string;
};

export default function DeleteSVG({ color }: DeleteSVGProps) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 14C2.0875 14 1.73438 13.8531 1.44062 13.5594C1.14687 13.2656 1 12.9125 1 12.5V2.5H0.75C0.5375 2.5 0.359375 2.42855 0.215625 2.28565C0.0718751 2.14273 0 1.96565 0 1.7544C0 1.54313 0.0718751 1.36458 0.215625 1.21875C0.359375 1.07292 0.5375 1 0.75 1H4V0.75C4 0.5375 4.07188 0.359375 4.21563 0.215624C4.35938 0.0718745 4.5375 0 4.75 0H7.25C7.4625 0 7.64063 0.0718745 7.78438 0.215624C7.92813 0.359375 8 0.5375 8 0.75V1H11.25C11.4625 1 11.6406 1.07145 11.7844 1.21435C11.9281 1.35727 12 1.53435 12 1.7456C12 1.95687 11.9281 2.13542 11.7844 2.28125C11.6406 2.42708 11.4625 2.5 11.25 2.5H11V12.491C11 12.9137 10.8531 13.2708 10.5594 13.5625C10.2656 13.8542 9.9125 14 9.5 14H2.5ZM9.5 2.5H2.5V12.5H9.5V2.5ZM4 11H5.5V4H4V11ZM6.5 11H8V4H6.5V11Z"
        fill={color ? color : "#FF7D6B"}
      />
    </svg>
  );
}
