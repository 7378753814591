// Packages
import React from "react";
import { IonPage } from "@ionic/react";
import { observable, computed } from "mobx";
import { inject, observer } from "mobx-react";
import {
  LoadingOutlined,
  LockOutlined,
  MailOutlined,
  SafetyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
// Styles
import { Input, Button, Alert } from "antd";
// Plugin Functions
import { authResetPassword } from "../../plugins/auth/auth";
// Type Definitions
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps {
  userStore: any;
}

@inject("userStore")
@observer
class ResetPassword extends React.Component<IProps> {
  @observable public code = "";
  @observable public newPassword = "";
  @observable public error = null;
  @observable public loading = false;

  @computed get email() {
    return this.props.userStore.proposedUserEmail;
  }

  public handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resError = await authResetPassword(
      this.props,
      this.email,
      this.code,
      this.newPassword
    );
    // If page didn't navigate, an error was returned
    this.error = resError;
    this.loading = false;
  };

  public handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    switch (target.name) {
      case "email":
        this.props.userStore.proposedUserEmail = target.value;
        break;
      case "newPassword":
        this.newPassword = target.value;
        break;
      case "code":
        this.code = target.value;
        break;
      default:
        console.log("Unknown form value change");
    }
  };

  public renderSubmitBtn() {
    if (this.loading) {
      return (
        <Button type="primary" htmlType="submit" className="form-submit-button sign-in-button">
          <LoadingOutlined />
        </Button>
      );
    } else {
      return (
        <Button type="primary" htmlType="submit" className="form-submit-button sign-in-button">
          Submit New Password
        </Button>
      );
    }
  }

  render() {
    return (
      <IonPage className="ion-padding">
        <div className="center-text form-container m-y-auto">
          <h1 className="sign-in-header">Reset your password</h1>
          <p className="sign-in-subheader">
              Please fill the details to reset your password
          </p>

          {this.error ? (
            <Alert message={this.error} type="error" showIcon />
          ) : (
            ""
          )}

          <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item className="form-item mb-0">
            <label htmlFor="email" id="emailLabel" className="label-sign-in">
                Email
              </label>
              <Input
                className="input-sign-in"
                id="email"
                name="email"
                prefix={<MailOutlined />}
                placeholder="Email"
                value={this.email}
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item className="form-item mb-0">
              <label htmlFor="newPassword" id="newPasswordLabel" className="label-sign-in">
                New Password
              </label>
              <Input
                className="input-sign-in"
                id="newPassword"
                name="newPassword"
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
                onChange={this.handleChange}
              />
            </Form.Item>

            <Form.Item className="form-item">
              <label htmlFor="code" id="codeLabel" className="label-sign-in">
                Code
                </label>
              <Input
                className="input-sign-in"
                id="code"
                name="code"
                prefix={<SafetyOutlined />}
                placeholder="6-digit code"
                onChange={this.handleChange}
              />
            </Form.Item>

            {this.renderSubmitBtn()}
          </Form>
        </div>
      </IonPage>
    );
  }
}

export default ResetPassword;
