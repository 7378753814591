import React from "react";
import "./CheckBox.scss"; // Make sure the CSS file is properly linked

interface IProps {
  htmlFor: string;
  checked: boolean;
  onChange?: any;
  type?: string;
}

class CheckBox extends React.Component<IProps> {
  render() {
    const { htmlFor, checked, onChange, type } = this.props;
    return (
      <label htmlFor={htmlFor}>
        <input
          type="checkbox"
          id={htmlFor}
          checked={checked}
          onChange={onChange}
        />
        <span className={`${type === "task" ? "task-round" : "routine-round"}`}></span>
      </label>
    );
  }
}

export default CheckBox;
