// Packages
import React from 'react';
import { IonPage } from '@ionic/react';
// Styles
import './Support.scss'


class Support extends React.Component {

  render() {
    return (
      <IonPage className="ion-padding scroll-page">
        <div className="support-intro-text center">
          <h3>We'd love to hear your feedback on how we can improve and make this a better experience for you.</h3>
          <h4>Please feel free to share: </h4>
          <ul>
            <li>Bug reports or issues encountered</li>
            <li>Feature requests or enhancements</li>
            <li>Barriers to adoption</li>
            <li>Testimonials</li>
          </ul>
        </div>

        <div className='support-container'>
          <script type="text/javascript" src="https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.js"></script>
          <style type="text/css" media="screen, projection">
          	@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
          </style>
          <iframe title="Feedback Form" className="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://goaldenhour.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Feedback+%26+Help&screenshot=No&searchArea=no&captcha=yes" scrolling="no" height="500px" width="80%" frameBorder="0" >
          </iframe>
        </div>
      </IonPage>
    );
  }
};

export default Support;
