// Packages
import React from 'react';
import { IonPage } from '@ionic/react';
// Type Definitions
import { RouteComponentProps } from 'react-router-dom';

class Privacy extends React.Component<RouteComponentProps> {
  render() {
    return (
      <IonPage className="ion-padding scroll-page">
        <div className="longform-text">
          <h1 className="center-text bold-text">Privacy Policy</h1>
          <p>Goalden Hour takes your privacy seriously, and we want you to know how we collect, use, share, and protect your information. This Privacy Policy tells you what information we collect from visitors of websites and services owned and controlled by the Company (the "website" ) and users of our Services, how we use that information, how we may share that information, how we protect your information, and your choices regarding your personal information.</p>
          <h3 className="center-text">Information We Collect from You</h3>
          <p>"Personal information" is information through which you can be personally identified, including any photographs you may upload. Personal Information that we collect may include your full name, credit card and/or other payment information (if applicable), your email address, phone number, certain health information, or any other information or data that you provide when using our website or Services. We collect personal information you choose to provide to us, including through registrations, applications, surveys, chat sessions or other inquiries. We do not collect information that would identify you unless you choose to provide it to us. You can choose not to provide us with certain information, but please be aware that may impact the Services provided to you.</p>
          <p>"General information" is information we obtain automatically from your connection, interaction with this website and the use of Cookies and other technologies. General information does not identify you personally. For instance, we do not identify your name from your Internet browser address, but we use it to compile statistics about visitors to our website and preferences of our visitors. General information we collect when you visit our website may include but is not limited to the domain and host from which you access the Internet, the Internet protocol address of the computer you are using ("IP Address"), the browser software you are using, the operating system you are using, the date and time that you access our website, the specific pages you visit on our website, the number of visits you make to our website, the number of visits to specific pages you make on our website, and products or services viewed on our website.</p>
          <p>"Cookies" are small pieces of information which a website stores on your computer and can later retrieve. Cookies cannot be read by a website other than the one that sets the cookie. We use cookies to enhance the use of our websire.</p>
          <h3 className="center-text">What We Do with Your Information</h3>
          <p>Goalden Hour respects your privacy and will not sell your personal information to third parties without your express permission. We may use your personal information and user activity to provide Services to you, respond to your inquiries, provide supplementary information to goals & activities you have chosen, or have a representative contact you regarding our products or services. Unless you have otherwise opted out of receiving email communication from us, you agree by using the website or Services, to allow us to use your email for communication and provision of Services consistent with this Privacy Policy.</p>
          <p>We may also use your personal information to update you on special offers related to our products or services, improve our products and services, provide product announcements or information regarding health & wellness topics, deliver other information we believe you will find most relevant, and useful and in any other way we may describe when you provide the information or to which you consent. We may occasionally contact you to gather customer service information to help us determine how we can improve our services and products to better meet your needs. We may also de-identify and/or aggregate your data for various business purposes including product, service and program development and improvement. De-identified data, in individual or aggregated form, may also be used for research purposes both internally by Goalden Hour or with research partners and other third parties for the advancement of clinical and scientific knowledge.</p>
          <p>This policy does not apply to personal information we collect from other sources. That information is governed by the agreement between us and the source of the data. We may combine or cross-reference your personal information with general information or other information we may have acquired about you or may acquire about you through other sources, including offline sources of information to help further customize the information, products or services we provide to you.</p>
          <p>We use the general information we collect from you to help us understand and analyze users of our website, including generating aggregate statistics about user activity. This data can then be used to tailor this website's content, deliver a better experience for our users. We may also collect, aggregate and maintain anonymous information about the visitors to our website. We may further share such aggregate, non-identifiable information with business partners, sponsors and other third parties.</p>
          <h3 className="center-text">How We Keep Your Information Secure</h3>
          <p>We seek to safeguard the security of your personal information and have implemented reasonable security measures consistent with accepted practices in the information technology industry to protect the confidentiality of your personal information and limit access to it. We have put in place a variety of information security measures to protect your personal information, including encryption technology, such as Secure Sockets Layer (SSL), to protect your personal information during data transport and at rest. However, despite our efforts to protect your personal information, there is always some risk that an unauthorized third party may find a way around our security systems or that transmissions of your personal information over the Internet will be intercepted. Unfortunately, we cannot guarantee the absolute security of your personal information, nor can we guarantee that information that you provide will not be intercepted while being transmitted to us over the Internet. Therefore, we urge you to also take every precaution to protect your personal information when you are on the Internet or using the website.</p>
          <h3 className="center-text">How to Opt Out</h3>
          <p>To opt out, please do not provide your personal information to us, or after providing your personal information to us, please send written notification to us that you no longer wish to receive information and communications from us or otherwise share your personal information. With respect to the collection and use of general information, you have the ability to disable or manage the use of cookies on your computer using controls in your browser. However, you are not able to opt out of the uses of general information otherwise collected as set forth in this policy. Please note that certain features of the website may not be available when cookies are disabled. To learn more about how to manage cookies, visit http://www.allaboutcookies.org.</p>
          <h3 className="center-text">Third Party Websites</h3>
          <p>Please be aware that our website may have links to third-party websites that may collect personal information about you. When you click on one of these third-party links, you are entering another website for which we have no responsibility. This Privacy Policy does not cover the information practices or policies of such third-party websites. We encourage you to read the privacy policies of all such websites since their privacy policies may be materially different than our Privacy Policy.</p>
          <p>In addition, we may rely on third-party advertisers, ad networks and ad servers to promote our website. This includes Facebook, Twitter or Google. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about our users. This may include information about users' behavior on this and other website or apps to serve them interest-based (behavioral) advertising. No information you share within our website will be shared with third-party advertisers. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement, you should contact the responsible advertiser directly.</p>
          <h3 className="center-text">Changes</h3>
          <p>We reserve the right to modify the terms of this Privacy Policy at any time and in our sole discretion, without notice. When the Privacy Policy is changed, modified, and/or amended, the revised Privacy Policy will be posted on our website. Modifications will be effective immediately. You should visit this web page periodically to review the Privacy Policy. You accept any such modifications to this Privacy Policy by continued use of our product after such modifications are made.</p>
          <h3 className="center-text">Contact Us</h3>
          <p>If you would like to update your personal information, delete your account, change your preferences or have any questions or concerns about your privacy, you may contact us at <a href="mailto:team@goaldenhour.com">team@goaldenhour.com</a>. Please note that some information may remain in our records after deletion of your account, including any information or records we are legally obligated to retain.</p>
        </div>
      </IonPage>
    );
  }
};

export default Privacy;