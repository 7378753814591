import { IonFooter, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <IonFooter>
      <IonToolbar>
        <div className="header-content-wrapper">
          <IonTitle>
            <Link to="/" className="navbar-link">
              <img
                src={`${process.env.PUBLIC_URL}/assets/logomark.svg`}
                className="logomark"
                alt="Goalden Hour logomark"
              />
              <span className="logotype">Goalden Hour</span>
            </Link>
          </IonTitle>
          <IonMenuButton />
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default Footer;
